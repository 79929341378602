import React, { useEffect, useState } from 'react';
import gs from '../services/gs';

const Select = ({ eachRow, handleChange }) => {
    const [post, setPost] = useState();

    useEffect(() => {
        if (eachRow.type === 'Reference') {
            const table = eachRow.refobjectRef.label;
            if (table !== 'db_object') {
                gs.getAll(table).then(res => {
                    setPost(res);
                });
            } else {
                gs.getSecureAll(table).then(res => {
                    setPost(res);
                });
            }
        } else {
            gs.getOptions(eachRow.object, eachRow.name).then(res => {
                setPost(res);
            });
        }
    }, [eachRow]);
    //
    return (
        <select className="form-select" aria-label="Default select example" defaultValue='' value={eachRow.data !== undefined ? eachRow.data.value : ''} onChange={(e) => handleChange(e, eachRow)}>
            <option>Please select</option>
            {post && post.map((eachOption) => {
                return <option key={eachOption.id} value={eachOption.id}>{eachOption[eachRow.refcolumn]}</option>
            })}
        </select>
    )
}

export default Select;