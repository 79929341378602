import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import gs from '../services/gs';
import { Link } from "react-router-dom";
import SubHeader from "../components/SubHeader";

const Admission = () => {
    const [tab, setTab] = useState('ug');
    const [ug, setUg] = useState();
    const [pg, setPg] = useState();
    const [phd, setPhd] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getListColumns('notification').then(res => {
            setColumns(res);
        });
        gs.getByType('notification', 75).then(res => {
            setUg(res);
        });
    }, []);

    const onClickTab = (tab) => {
        setTab(tab);
        switch (tab) {
            case "ug":
                gs.getByType('notification', 75).then(res => {
                    setUg(res);
                });
                break;
            case "pg":
                gs.getByType('notification', 76).then(res => {
                    setPg(res);
                });
                break;
            case "phd":
                gs.getByType('notification', 77).then(res => {
                    setPhd(res);
                });
                break;
            default:
                break;
        }
    }

    const tabs = [{
        label: "U.G. ADMISSIONS DETAILS",
        name: "ug"
    },
    {
        label: "P.G. ADMISSIONS DETAILS",
        name: "pg"
    },
    {
        label: "M.Phil./Ph.D. PROGRAM ADMISSIONS DETAILS",
        name: "phd"
    }];

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Admissions</h2>
                <ul className="nav nav-tabs">
                    {tabs.map((eachTab) =>
                        <li key={eachTab.name} className="nav-item">
                            <a className={tab === eachTab.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachTab.name)}>{eachTab.label}</a>
                        </li>
                    )}
                </ul>

                <div className="tab-content">
                    {tab === 'ug' ? (
                        <div id='ug'>
                            {ug ? (<ListView table="syllabus" data={ug} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                    {tab === 'pg' ? (
                        <div id='pg'>
                            {pg ? (<ListView table="syllabus" data={pg} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                    {tab === 'phd' ? (
                        <div id='phd'>
                            {phd ? (<ListView table="syllabus" data={phd} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                </div>
                <Link className="mt-2 d-block" style={{ width: '12rem' }} to="/old-announcements">Old Announcements</Link>
            </div>
        </div>
    );
}

export default Admission;