import React, { useState } from 'react';
import ListView from "./ListView";

const TabUGPG = ({ columns, ugData, pgData }) => {
    const [tab, setTab] = useState('ug');

    const onClickTab = (tab) => {
        setTab(tab);
    }

    const tabs = [{
        label: "UG",
        name: "ug"
    },
    {
        label: "PG",
        name: "pg"
    }];

    return (
        <>
            <ul className="nav nav-tabs">
                {tabs.map((eachRow) => {
                    return (
                        <li key={eachRow.name} className="nav-item">
                            <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                        </li>
                    )
                })}
            </ul>

            <div className="tab-content">
                {tab === 'ug' ? (
                    <div id="ug">
                        {ugData ? (<ListView table="notification" data={ugData} columns={columns} download="true" pageSize="20" />) : ("No data found")}
                    </div>
                ) : (
                    <div id="pg">
                        {pgData ? (<ListView table="notification" data={pgData} columns={columns} download="true" pageSize="20" />) : ("No data found")}
                    </div>
                )}
            </div>
        </>
    )
}

export default TabUGPG;