import SubBanner from "../../components/SubHeader";
const HelpDesk = () => {
    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">HelpDesk</h3>
                <img className="my-3 mw-100" src="/images/library/helpdesk.jpg" alt="Help desk" />
            </div>
        </>
    )
}

export default HelpDesk;