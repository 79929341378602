import SubHeader from "../components/SubHeader";

const GoodGovernance = () => {

  return (
    <div>
      <SubHeader />
      <div className="container my-4">
        <h2 className="heading">Good Governance/ ಉತ್ತಮ ಹಾಗೂ ಪಾರದರ್ಶಕ ಆಡಳಿತ (ಸುಶಾಸನ)</h2>
        <div className="heading-section-separator"><div className="separator-wrapper style-solid"></div></div>
        <table>
          <tbody>
            <tr>
              <th >ಕ್ರ.ಸಂ.</th>
              <th >ಮಾಹಿತಿಗಳು</th>
              <th >ವಿವರ</th>
            </tr>
            <tr>
              <td> 1</td>
              <td>ಯುಜಿಸಿ ಮಾರ್ಗಸೂಚಿಗಳು ಹಾಗೂ ಮಾನದಂಡಗಳಂತೆ ಮಾನ್ಯ ರಾಜ್ಯಪಾಲರು ಮತ್ತು ಕುಲಾಧಿಪತಿಗಳಿಂದ ಅನುಮೋದನೆಗೊಂಡ ಸಂಬಂಧಪಟ್ಟ ವಿಶ್ವವಿದ್ಯಾಲಯದ ಎಲ್ಲಾ ಪರಿನಿಯಮಗಳು (ಪಿಡಿಎಫ್ ವಿನ್ಯಾಸದಲ್ಲಿ)</td>
              <td>
                <p ><a href="../load?page=GUKSTATUTES">ಶಾಸನಗಳು</a></p>
                <p ><a href="../load?page=REGULATIONS">ಪರಿನಿಯಮಾವಳಿಗಳು</a></p>
              </td>
            </tr>
            <tr >
              <td> 2</td>
              <td >ವಾರ್ಷಿಕ ಆಯವ್ಯಯದ ಸಂಪೂರ್ಣ ಮಾಹಿತಿಗಳನ್ನೊಳಗೊಂಡ ಕಾಲಾನುಕ್ರಮವಾಗಿ ವಾರ್ಷಿಕ ಆಯವ್ಯಯಗಳ ಎಲ್ಲಾ ದಾಖಲೆಗಳು</td>
              <td ><a href="https://drive.google.com/file/d/1BiTcin3_BItmbVlUZiUmB9Zx8s8tCuO-/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr>
            <tr >
              <td> 3</td>
              <td >ಕಾಲಾನುಕ್ರಮವಾಗಿ ಎಲ್ಲಾ ವಾರ್ಷಿಕ ವರದಿಗಳು ಹಾಗೂ ಲೆಕ್ಕಪರಿಶೋಧನಾ ವರದಿಗಳು</td>
              <td ><a href="../load?page=FINANCIAL-INFO">ವೀಕ್ಷಿಸಿ</a></td>
            </tr>
            <tr >
              <td> 4</td>
              <td >ಸಿಂಡಿಕೇಟ್, ಶೈಕ್ಷಣಿಕ ಪರಿಷತ್ತು ಹಾಗೂ ಹಣಕಾಸು ಸಮಿತಿಗಳ ರಚನೆಯ ವಿಧಿ ವಿಧಾನ, ಈ ಪ್ರಾಧಿಕಾರಗಳ ಪಾತ್ರ ಹಾಗೂ ಹೊಣೆಗಾರಿಕೆಗಳು, ಇವುಗಳ ಸದಸ್ಯರ ವಿವರಗಳು. ಈ ಪ್ರಾಧಿಕಾರಗಳ ಸಭೆಗಳ ಕಾರ್ಯಸೂಚಿ ಮತ್ತು ನಡವಳಿಗಳು(ಪಿಡಿಎಫ್ ವಿನ್ಯಾಸದಲ್ಲಿ).</td>
              <td >
                <p ><a href="../load?page=proce-of-syndicate-meeting">ಸಿಂಡಿಕೇಟ ಸಭೆಯ ನಡುವಳಿಗಳು</a></p>
                <p ><a href="../load?page=proce-of-accademic-council">ಶೈಕ್ಷಣಿಕ ಪರಿಷತ್ತು ಸಭೆಯ ನಡುವಳಿಗಳು</a></p>
              </td>
            </tr>
            <tr >
              <td> 5</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದ ಎಲ್ಲಾ ಸ್ಥಿರಾಸ್ತಿಗಳ ವಿವರಗಳು.</td>
              <td ><a href="https://drive.google.com/file/d/15yTrxzk7Gevpka22GjFt5SZvK7H7jCTE/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr>
            <tr >
              <td >6</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದ ಎಲ್ಲಾ ಸ್ಥಿರಾಸ್ತಿಗಳ ಚರಾಸ್ತಿಗಳ ಅಧ್ಯಯನ ವಿಭಾಗವಾರು ವಿವರಗಳು.</td>
              <td ><a href="https://drive.google.com/file/d/16UjFQwuS2Lyc4PPCh9LSco4TwngBvRQg/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr>
            <tr >
              <td >7</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದ ಎಲ್ಲ ಬ್ಯಾಂಕ್ ಖಾತೆಗಳ ವಿವರಗಳು, ಬ್ಯಾಂಕ್ ಖಾತೆಗಳ ಆರಂಭ ಮತ್ತು ನಿರ್ವಹಣೆಯ ಉದ್ದೇಶಗಳು ಹಾಗೂ ಬ್ಯಾಂಕ್ ಖಾತೆಗಳಲ್ಲಿ ಲಭ್ಯವಿರುವ ಬಾಂಕ್ ಬ್ಯಾಲೆನ್ಸ್ ಹಾಗೂ ಠೇವಣಿಗಳ ಬಗ್ಗೆ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td> 8</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯಕ್ಕೆ ಪ್ರಸ್ತುತ ಆರ್ಥಿಕ ವರ್ಷದಲ್ಲಿ ವಿವಿಧ ಸರ್ಕಾರದ ಇಲಾಖೆಗಳಿಂದ/ಪ್ರಾಧಿಕಾರಗಳಿಂದ/ನಿಗಮಗಳಿಂದ/ ಮಂಡಳಿಗಳಿಂದ/ ಸಂಸ್ಥೆಗಳಿಂದ ಮಂಜೂರಾಗಿರುವ ಅನುದಾನ, ಮಂಜೂರಾತಿ ಆದೇಶಗಳು, ಅನುದಾನವನ್ನು ಜಮಾ ಮಾಡಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಯ ವಿವರಗಳು, ಅನುದಾನದ ಉಪಯೋಗಕ್ಕಾಗಿ/ಬಳಕೆಗಾಗಿ ತೆಗೆದುಕೊಂಡ ಕ್ರಮ, ಕ್ರಿಯಾ ಯೋಜನೆಯ ವಿವರ, ಸಾಧಿಸಿರುವ ಮಾಹೆವಾರು ಭೌತಿಕ ಹಾಗೂ ಆರ್ಥಿಕ ಪ್ರಗತಿಯ ವಿವರಗಳು</td>
              <td >
                <p><a href="https://drive.google.com/file/d/1RaevkieqMFQWzvHSAIjoNbCZAH4VE6uH/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></p>
                <p><a href="https://drive.google.com/file/d/1avU5EZ_dlWJJGIW-Xs7meoZ8DlPgvxWD/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></p>
              </td>
            </tr>
            <tr >
              <td >9</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯಕ್ಕೆ ಪ್ರಸ್ತುತ ಆರ್ಥಿಕ ವರ್ಷದಲ್ಲಿ ಸರ್ಕಾರೇತರ ಮೂಲಗಳಿಂದ ಮಂಜೂರಾಗಿರುವ ಸಂದಾಯವಾಗಿರುವ ಸಂಪನ್ಮೂಲಗಳ ವಿವರಗಳು (Including all forms of own source Revenue ) ಅದರ ಜಮಾ, ಖರ್ಚು ಹಾಗೂ ಠೇವಣಿಸಿದ ಬ್ಯಾಂಕ್&nbsp; ಖಾತೆಯ ವಿವರಗಳು</td>
              <td ><a href="https://drive.google.com/file/d/1rJ035B8nSUyQmun8RIrg1dnKSs2mPZC7/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr>
            <tr >
              <td >10</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದ&nbsp; Pension Fund ಬಗ್ಗೆ ಸಂಪೂರ್ಣ ವಿವರ, ಮಾಹೆವಾರು ಜಮಾ ಮತ್ತು ವಿಲೇವಾರಿ ವಿವರಗಳು</td>
              <td >
                <p><a href="https://drive.google.com/file/d/1H5cnvWcJEQNU1CcNyHMy3jj8I8GEWEKg/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></p>
                <p><a href="https://drive.google.com/file/d/1hMu3oX17765e1lYzvwShRwHFkEFes7YD/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></p>
              </td>
            </tr>
            <tr >
              <td >11</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯಲದಲ್ಲಿ ಪ್ರತಿ ತಿಂಗಳು HRMS ಹಾಗೂ non-HRMS pyments ವಿವರಗಳು</td>
              <td >
                <p><a href="https://drive.google.com/file/d/1Hnvn9PiBqRdAlEgOd-p65Jhv4k0Bexgb/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></p>
                <p><a href="https://drive.google.com/file/d/1I7p8y5eLcGHMCS9nd36fm7VI_vYliHAH/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></p>
              </td>
            </tr>
            <tr >
              <td >12</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯಲದಲ್ಲಿ ಇರುವ ವಿವಿಧ&nbsp;Corpus Fund&nbsp; ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td >13</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯಲದಲ್ಲಿ ಮಾಹೆವಾರು ಮಾಸಿಕ ಆದಾಯ ಹಾಗೂ ವೆಚ್ಚದ ಸಂಪೂರ್ಣ ವಿವರಗಳು</td>
              <td ><a href="https://drive.google.com/file/d/1klD-kcN1XqtMlXrnLf6C9dAqSb4Z6hdd/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr>
            <tr >
              <td >14</td>
              <td >2022-23ನೇ ಸಾಲಿನಲ್ಲಿ ಪ್ರಕಟಿಸಲಾಗಿರುವ ಟೆಂಡರ್‍ಗಳು Technical ಮತ್ತು Financial bid&nbsp; ನಡವಳಿಗಳು. ಖರೀದಿಗಳ ಆದೇಶಗಳು, ಕೈಗೊಳ್ಳಲಾದ ಸಂಗ್ರಹಣೆಗಳ ಸಂಪೂರ್ಣ ವಿವರ ಹಾಗೂ ಅನ್ವಯಿಸುವ ಸಂಗ್ರಹಣೆಗಳಿಗೆ ಸಂಬಂಧಪಟ್ಟ&nbsp; Stock book register extract</td>
              <td ><a href="https://drive.google.com/file/d/17qYhYrYcEEy0GHQS0BjmPKDKOdxwV5ke/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr>
            <tr >
              <td> 15</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯವು ಪ್ರಸ್ತುತ ತೆಗೆದುಕೊಂಡಿರುವ ಎಲ್ಲ ಮೂಲಭೂತ ಅಭಿವೃದ್ಧಿ ಕಾಮಗಾರಿಗಳ ವಿವರಗಳು (tender details, approved estimate, work order agreement with the agency ) ಹಾಗೂ ಪ್ರತಿ ತಿಂಗಳು ಸಾಧಿಸುವ ಆರ್ಥಿಕ ಮತ್ತು ಭೌತಿಕ ಪ್ರಗತಿಯ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td> 16</td>
              <td >ಸ್ಥಾಪನೆ ವರ್ಷ ಹಾಗೂ ಮೂಲ ಉದ್ದೇಶಗಳ ಮಾಹಿತಿಯೊಂದಿಗೆ ವಿಶ್ವವಿದ್ಯಾಲಯ ಅಧ್ಯಯನ ವಿಭಾಗ, ಅಧ್ಯಯನ ಕೇಂದ್ರ, ಅಧ್ಯಯನ ಪೀಠಗಳ ವಿವರಗಲು ಅಧ್ಯಯನ ವಿಭಾಗಗಳಲ್ಲಿ ನಡೆಸಲಾಗುತ್ತಿರುವ ಶೈಕ್ಷಣಿಕ ಕಾರ್ಯಕ್ರಮಗಳು ಕೋರ್ಸುಗಳು, Details of accreditation etc.,&nbsp; ಬೋಧಕ ಸಿಬ್ಬಂದಿಯ curriculum vitae ಹಾಗೂ ಅವರ ಆಸಕ್ತಿಗಳು, ಅವರಿಂದ ಕೈಗೆಳ್ಳಲಾದ ಸಂಶೋಧನೆ, ಅವರ ಪ್ರಕಟಣೆಗಳು, ಸಾಧನೆಗಳು ಮತ್ತು ಅವರ ಸಂಪರ್ಕದ ವಿವರಗಳು; ಇದೇ ರೀತಿಯ&nbsp; ಮಾಹಿತಿಯನ್ನು ವಿಶ್ವವಿದ್ಯಾಲಯದ ಎಲ್ಲಾ ಸಂಯೋಜಿತ ಕಾಲೇಜುಗಳು ಮತ್ತು ಘಟಕ ಕಾಲೇಜುಗಳು. ಈ ವರ್ಷವು ಸೇರಿದಂತೆ ಕಳೆದ ಐದು ವರ್ಷಗಳ ಶೈಕ್ಷಣಿಕ ಕಾರ್ಯಕ್ರಮಗಳ ಸೆಮಿಸ್ಟರವಾರು ಫಲಿತಾಂಶಗಳ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td >17</td>
              <td >ಕರ್ತವ್ಯ ಹಾಗೂ ಹೊಣೆಗಾರಿಕೆಯ ವಿವರಗಳೊಂದಿಗೆ ಬೋಧಕೇತರ ಸಿಬ್ಬಂದಿಯ ಮಾಹಿತಿ</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr>
              <td> 18</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ನಡೆಯುತ್ತಿರುವ ವಿವಿಧ&nbsp; Programmes&nbsp; ಮತು Courses ಗಳ ಶೀರ್ಷಿಕೆ ಮಾನ್ಯತೆಯ ವಿವರಗಳು, ವಿವರವಾದ ಶುಲ್ಕ ವಿನ್ಯಾಸ, ಕೋರ್ಸ್‍ಗಳ ಪಠ್ಯಕ್ರಮ (syllabus and Curriculum ) 2022-23ನೇ ಸಾಲಿನ ಅನುಮೋದಿತ ವಿದ್ಯಾರ್ಥಿ ಪ್ರಮಾಣ&nbsp; ಮತ್ತು ಪ್ರವೇಶ ಪಡೆದ ವಿದ್ಯಾರ್ಥಿ ಪ್ರಮಾಣ&nbsp; ವಿಶ್ವವಿದ್ಯಾಲಯಗಳು ನಡೆಸುವ ಎಲ್ಲಾ ಪದವಿ ಮತ್ತು ಸ್ನಾತಕೋತ್ತರ&nbsp; ಕಾರ್ಯಕ್ರಮಗಳು, ಇದೇ ರೀತಿಯ ಮಾಹಿತಿಯನ್ನು ಅದರ ಎಲ್ಲಾ ಸಂಯೋಜಿತ ಕಾಲೇಜುಗಳು ಮತ್ತು ಘಟಕ ಕಾಲೇಜುಗಳು. ಈ ವರ್ಷವು ಸೇರಿದಂತೆ ಕಳೆದ ಐದು ವರ್ಷಗಳ ಶೈಕ್ಷಣಿಕ ಕಾರ್ಯಕ್ರಮಗಳ ಸೆಮಿಸ್ಟರವಾರು ಫಲಿತಾಂಶಗಳ ವಿವರಗಳು</td>
              <td >
                <p ><a href="../load?page=admissions">ವಿವಿಧ ಪ್ರೋಗ್ರಾಂಗಳ ವಿವರ</a></p>
                <p ><a href="../load?page=Fees-Structure">ಶುಲ್ಕ ವಿನ್ಯಾಸ</a></p>
                <p ><a href="../load?page=academic">ಕೋರ್ಸುಗಳ ಪಠ್ಯಕ್ರಮ</a></p>
                <p ><a href="../load?page=UUCMS-GUK">ವಿಧ್ಯಾರ್ಥಿ ಪಟ್ಟಿ</a></p>
              </td>
            </tr >
            <tr >
              <td> 19</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದ ಸಂಯೋಜಿತ ಕಾಲೇಜುಗಳ ವಿವರ, ಅವರ ಅನುಮೋದಿತ&nbsp; courses and course intake, details of accreditation ect.</td>
              <td ><a href="../load?page=UUCMS-GUK">ಸಂಯೋಜಿತ ಕಾಲೇಜುಗಳ ವಿವರ</a></td>
            </tr >
            <tr >
              <td >20</td>
              <td >ಸೆಮಿಸ್ಟರವಾರು ತರಗತಿಗಳ ವೇಳಾಪಟ್ಟಿ ಹಾಗೂ ಸ್ನಾತಕ ಮತ್ತು ಸ್ನಾತಕೋತ್ತರ ಕಾರ್ಯಕ್ರಮಗಳ ಕಾರ್ಯ ಹಂಚಿಕೆ ವಿವರಗಳು ಹಾಗೂ ಶೈಕ್ಷಣಿಕ ಕಾರ್ಯಕ್ರಮಗಳ ದಿನಾಂಕವಾರು ಅನುಸೂಚನೆ/ವೇಳಾಪಟ್ಟಿ</td>
              <td ><a href="../load?page=Calendar-of-Events">ತರಗತಿಗಳ ಸೇಮಿಸ್ಟರವಾರು ವೇಳಾಪಟ್ಟಿ</a></td>
            </tr >
            <tr >
              <td >21</td>
              <td >ಸೆಮಿಸ್ಟರವಾರು ಪರೀಕ್ಷಾ ವೇಳಾಪಟ್ಟಿ, ಪರೀಕ್ಷಾ ಶುಲ್ಕದ ವಿವರಗಳು ಹಾಗೂ ಪರೀಕ್ಷಾ ಪ್ರಕ್ರಿಯೆಯ ಹಂತಗಳ ಹಾಗೂ ಒಟ್ಟಾರೆ ಅವಧಿಯ ಮಾಹಿತಿ ಮತ್ತು&nbsp; ಫಲಿತಾಂಶದ ವಿವರಗಳು</td>
              <td ><a href="../load?page=Admissions">ದಂಡ ಶುಲ್ಕ ಮತ್ತು ವಿವರ</a></td>
            </tr >
            <tr >
              <td> 22</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯವು ವಿಧಿಸುತ್ತಿರು ಎಲ್ಲಾ&nbsp; Fines and Penalties ಗೆ ಸಂಬಂಧಿಸಿದಂತೆ ವಿವಿಧ ಆದೇಶಗಳು ಹಾಗೂ ಮಾಹೆವಾರು ಇಂತಹ&nbsp;Fines and Penalties&nbsp;ಇಂದ ಸಂಗ್ರಹಿಸಲಾಗುತ್ತಿರುವ ಮೊತ್ತ</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr >
            <tr >
              <td >23</td>
              <td >ಸಂಶೋಧನಾ ಕೇಂದ್ರಗಳು ಸೇರಿದಂತೆ ಅಧ್ಯಯನ ಪೀಠಗಳು, ಯೋಜನೆಗಳು, ಪ್ರಕಟಣೆಗಳು, ವರ್ಷವಾರು ಪಡೆದ ಅನುದಾನಗಳು, ಜರ್ನಲ್‍ಗಳು ಮತ್ತು ಉದ್ಯಮ ಪ್ರಾಯೋಜಿತ ಸಂಶೋಧನೆಯ&nbsp; ವಿವರಗಳು</td>
              <td ><a href="https://drive.google.com/file/d/1BZg4H6YBxYc8T76imkVRVNylinCsPVaz/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr >
            <tr >
              <td> 24</td>
              <td >ಗ್ರಂಥಸೂಚಿ (catalogue), ಡೇಟಾಬೇಸ್ (ದತ್ತ ಸಂಚಯ) ನೊಂದಿಗೆ ಚಂದಾದಾರರಾಗಿರುವ ಎಲ್ಲಾ ಜರ್ನಲ್‍ಗಳ ಹೆಸರುಗಳು, ಡಿಜಿಟಲ್ ಸಂಪುಟಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಎಲ್ಲಾ ಸಂಪುಟಗಳು, ವಿಶ್ವವಿದ್ಯಾಲಯದ ಜರ್ನಲ್‍ಗಳನ್ನು ವಿಶ್ವವಿದ್ಯಾಲಯದ ಪ್ರಕಟಣೆಗಳು, ವಿಶ್ವವಿದ್ಯಾಲಯದ ಬೋಧಕರ ಪ್ರಕಟಣೆಗಳು, ವಿಶ್ವವಿದ್ಯಾಲಯದ ಪ್ರಶ್ನೆ ಪತ್ರಿಕೆಗಳು ಮತ್ತು ಪತ್ರಾಗಾರಗಳು, ಗ್ರಂಥಾಲಯದಲ್ಲಿ ಸಮಯ, ಅಲ್ಲಿನ ಸೇವೆಗಳು, ಗ್ರಂಥಾಲಯದಲ್ಲಿ ಲಭ್ಯವಿರುವ ಪೋಟೋಕಾಫಿ ಮುದ್ರಣ ಸ್ಕ್ಯಾನ್, ವಿತರಣೆ, ತಾಂತ್ರಿಕ ಸಹಾಯ, ರೆಫರಲ್ ಸೇವೆ/ಸಂಶೋಧನಾ ಸಹಾಯದಂತಹ ವಿವರಗಳು</td>
              <td ><a href="https://drive.google.com/file/d/1vEQ-8QyLfxM70EH6YdQsn0FowPf06Cwv/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr >
            <tr >
              <td >25</td>
              <td >ವಾರ್ಷಿಕ ರಜಾ ದಿನಗಳ ಸೂಚಿಪಟ್ಟಿ</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td >26</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯಗಳ ವಿವಿಧ ಶಾಖೆಗಳಿಂದ ಹೊರಡಿಸಲಾಗುವ ಎಲ್ಲಾ ಶೈಕ್ಷಣಿಕ ಮತ್ತು ಆಡಳಿತಾತ್ಮಕ ಮತ್ತು ಇತರೆ ಸುತ್ತೋಲೆಗಳನ್ನು ಆ ದಿನವೇ ಜಾಲತಾಣದಲ್ಲಿ ಅಳವಡಿಸುವುದು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr >
            <tr >
              <td >27</td>
              <td >ಪ್ಲೇಸ್‍ಮೆಂಟ್ ಕೋಶಗಳ ವಿವರಗಳು ಮತ್ತು ಕಳೆದ ಮೂರು ವರ್ಷಗಳಲ್ಲಿ ನೀಡಲಾದ ಉದ್ಯೋಗದ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td> 28</td>
              <td >ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಸಂಪರ್ಕ ಮತ್ತು ವಿಶ್ವವಿದ್ಯಾಲಯದಿಂದ ಪ್ರಾರಂಭಿಸಿ ಹಳೆಯ ವಿದ್ಯಾರ್ಥಿಗಳ ಸಂಘದ ವಿವರಗಳು ಮತ್ತು ಅದರ ಸದಸ್ಯತ್ವದ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr >
            <tr >
              <td> 29</td>
              <td >ಯೋಜನೆಗಳು, ಇಂಟರ್ನ್‍ಶಿಪ್‍ಗಳು ಮತ್ತು ವಿದ್ಯಾರ್ಥಿ ಮಾರ್ಗದರ್ಶನ ಕಾರ್ಯಕ್ರಮ, ಇಂಟರಶಿಪ್/ಅಪ್ರಂಟಿಸ್‍ಶಿಪ್ ಎಂಬೆಡೆಡ್ ಪದವಿ ಕಾರ್ಯಕ್ರಮಗಳ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td> 30</td>
              <td >ಪಿಎಚ್‍ಡಿ ಪ್ರವೇಶಗಳು, ವಿದ್ಯಾರ್ಥಿ ಪ್ರಮಾಣ ಸಂಖ್ಯೆ ಹಾಗೂ ಪ್ರಸ್ತುತ ಪಿಎಚ್‍ಡಿ&nbsp; ವಿದ್ವಾಂಸರ ವಿವರಗಳ ಜೊತೆಗೆ ಸಂಶೋಧನಾ ವಿವರಗಳು ಮತ್ತು ನಿಯೋಜಿಸಲಾದ&nbsp; ಕೆಲಸ, ವಿಶ್ವವಿದ್ಯಾಲಯದಿಂದ ವಿಷಯವಾರು ಗುರುತಿಸಲಾಗಿರುವ&nbsp; Research Centres ಸಂಪೂರ್ಣ ವಿವರಗಳು Research Guides ವಿವರ ಹಾಗೂ ಅವರಿಗೆ&nbsp; ನಿಯೋಜಿಸಲಾಗಿರುವ ಸಂಶೋಧಕರ ವಿವರ&nbsp;</td>
              <td ><a href="../load?page=MPHIL-PHD-ADM">ವಿಕ್ಷೀಸಿ</a></td>
            </tr>
            <tr >
              <td >31</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಅಂತರಾಷ್ಟ್ರೀಯ ಕೋಶದ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td >32</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಆವಿಷ್ಕಾರ ಕೇಂದ್ರದ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td> 33</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದಿಂದ ಜಾರಿಗೊಳಿಸಲಾದ ಕಲ್ಯಾಣ ಕಾರ್ಯಕ್ರಮಗಳು, ವಿದ್ಯಾರ್ಥಿ ವೇತನಗಳು, ಉಚಿತ ಶಿಕ್ಷಣ, ಹಾಸ್ಟೆಲ್ ಸೌಲಭ್ಯಗಳು ಇತ್ಯಾದಿಗಳ ವಿವರಗಳು ಹಾಗೂ ನಿಗಧಿಪಡಿಸಿರುವ ಅನುದಾನ ಆರ್ಥಿಕ ಮತ್ತು ಭೌತಿಕ ವಿವರಗಳು</td>
              <td >
                <p ><a href="../load?page=HOSTEL-DETAIL" target="_blank" rel="noopener">ಹಾಸ್ಟೆಲ ಸೌಲಭ್ಯಗಳು</a></p>
                <p ><a href="../load?page=SCHOLERSHIP-DETAIL" target="_blank" rel="noopener">ವಿದ್ಯಾರ್ಥಿ ವೇತನಗಳು</a></p>
              </td>
            </tr>
            <tr >
              <td> 34</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ವಿದ್ಯಾರ್ಥಿ ಕುಂದುಕೊರತೆ ಕೋಶದ ವಿವರಗಳು ಹಾಗೂ ದಾಖಲಾಗುವ ವಿವಿಧ ಕುಂದುಕೊರತೆಗಳ ವಿಲೇವಾರಿಯ ವಿವರಗಳು.</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td >35</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಆನ್‍ಲೈನ್ ಮತ್ತು ಡಿಜಿಟಲ್ ಕಾರ್ಯಕ್ರಮಗಳ ಸಂಪೂರ್ಣ ವಿವರಗಳು.</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td >36</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಲಭ್ಯವಿರುವ ದೂರ ಶಿಕ್ಷಣ ಕೋರ್ಸ್‍ಗಳ ವಿವರಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
            <tr >
              <td >37</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯವು ಸಹಿ ಮಾಡಿರುವ ಎಲ್ಲಾ ಒಡಂಬಡಿಕೆ (MoU) ಗಳ ವಿವರಗಳು (ಪಿಡಿಎಫ್&nbsp; ವಿನ್ಯಾಸದಲ್ಲಿ)</td>
              <td ><a href="https://drive.google.com/file/d/1q3zLSHqPE1-MIFhRPiSAxYEwpIQ1cdD7/view?usp=share_link">ವೀಕ್ಷಿಸಿ</a></td>
            </tr>
            <tr >
              <td >38</td>
              <td >ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ನಡೆಯುವ ಎಲ್ಲಾ ಪದೊನ್ನತಿ ಹಾಗೂ CAS ನಡವಳಿಗಳು</td>
              <td >ಶೀಘ್ರದಲ್ಲೇ ನವೀಕರಿಸಲಾಗುತ್ತದೆ</td>
            </tr>
          </tbody >
        </table >
        <br />
        <h3 >ಇತರೆ ಮಾಹಿತಿಗಳು</h3>
        <table border="1">
          <tbody>
            <tr>
              <td >1. <a href="https://drive.google.com/file/d/122NsS_6oCxr25ii67v14TFa6Y4O-_KGI/view?usp=share_link">ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಪ್ರಸ್ತುತ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿರುವ ಶಿಕ್ಷಕ ಸಿಂಬ್ಬಂದಿಗಳ ಪಟ್ಟಿ</a></td>
            </tr>
            <tr>
              <td >2. <a href="https://drive.google.com/file/d/1_phxbAeubUsvrONz77ygePsy-Cq-gFU9/view?usp=share_link">ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಪ್ರಸ್ತುತ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿರುವ ಶಿಕ್ಷಕೇತರ ಸಿಂಬ್ಬಂದಿಗಳ ಪಟ್ಟಿ</a></td>
            </tr>
            <tr>
              <td >3. <a href="https://drive.google.com/file/d/1QQchc0Uk5v_SE6VB9o7LIcf4McvoCfuv/view?usp=share_link">ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಪ್ರಸ್ತುತ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿರುವ ಹೊರಗುತ್ತಿಗೆ ಸಿಂಬ್ಬಂದಿಗಳ ಪಟ್ಟಿ</a></td>
            </tr>
            <tr>
              <td >4. <a href="https://drive.google.com/file/d/1KSi8-QlJwy4whyyLkhu4EoceJijmNGq8/view?usp=share_link">ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಶಿಕ್ಷಕೇತರ ಪಿಂಚಣಿದಾರರ ಪಟ್ಟಿ</a></td>
            </tr>
            <tr>
              <td >5. <a href="https://drive.google.com/file/d/1qD5J0O4s8jA4kGjASxhFThK_x4ZVB73W/view?usp=share_link">ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಶಿಕ್ಷಕ ಪಿಂಚಣಿದಾರರ ಪಟ್ಟಿ</a></td>
            </tr>
            <tr>
              <td >6. <a href="https://drive.google.com/file/d/1lYCAQeGCuSACThCRkjB8EBKf0-ekib_v/view?usp=share_link">ವಿಶ್ವವಿದ್ಯಾಲಯದ ಶಿಕ್ಷಕೇತರ ಕುಟುಂಬ ಪಿಂಚಣಿದಾರರ ಪಟ್ಟಿ</a></td>
            </tr>
            <tr>
              <td >7. <a href="https://drive.google.com/file/d/1PRbXrnS4NqP7WN-KULnHLeVb4I9z8yEY/view?usp=share_link">ವಿಶ್ವವಿದ್ಯಾಲಯದ ಶಿಕ್ಷಕ ಕುಟುಂಬ ಪಿಂಚಣಿದಾರರ ಪಟ್ಟಿ</a></td>
            </tr>
            <tr>
              <td >8. <a href="https://drive.google.com/file/d/17ctyCcaAY0K4pXxUaOO2wLgCjjeIii4a/view?usp=share_link">ವಿಶ್ವವಿದ್ಯಾಲಯದಲ್ಲಿ ಕಾರ್ಯವಿರ್ವಹಿಸುತ್ತಿರುವ ಪೀಠಗಳ ಪಟ್ಟಿ</a></td>
            </tr>
            <tr>
              <td >9. <a href="https://drive.google.com/file/d/1EGNY6n1ohPBprj76YYblbUqB2T6_kWhw/view?usp=share_link">ದಿನಾಂಕ 01.12.2022 ರಂದು ಇದ್ದಂತೆ ವಿಶ್ವವಿದ್ಯಾಲಯದ ಶಿಕ್ಷಕ ಮತ್ತು ಶಿಕ್ಷಕೇತರ ನೌಕರರ, ಮಂಜೂರಾದ, ಕೆಲಸ ನಿರ್ವಹಿಸುತ್ತಿರುವ ಮತ್ತು ಖಾಲಿ ಇರುವ ಹುದ್ದೆಗಳ ವಿವರ</a></td>
            </tr>
          </tbody>
        </table>
      </div >
    </div >
  );
};

export default GoodGovernance;