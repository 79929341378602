import { instAxios } from './helper';
import authHeader from "./auth-header";

const getById = async (table, id) => {
    const res = await instAxios.get(`/${table}/${id}`);
    return res.data;
}

const getSecureByName = async (table, name) => {
    const res = await instAxios.get(`/${table}/name?name=${name}`, { headers: authHeader() });
    return res.data;
}

const getByName = async (table, name) => {
    const res = await instAxios.get(`/${table}/name?name=${name}`);
    return res.data;
}

const getByType = async (table, type) => {
    const res = await instAxios.get(`/${table}/type?type=${type}`);
    return res.data;
}

const getBy = async (table, column, value) => {
    const res = await instAxios.get(`/${table}/${column}?${column}=${value}`);
    return res.data;
}

const getListColumns = async (table) => {
    const res = await instAxios.get(`/db_column/oe?object=${table}&element=list`);
    return res.data;
}

const getFormColumns = async (table) => {
    const res = await instAxios.get(`/db_column/oe?object=${table}&element=form`);
    return res.data;
}

const getSections = async (tableId) => {
    const res = await instAxios.get(`/section/object?object=${tableId}`);
    return res.data;
}

const getOptions = async (object, element) => {
    const res = await instAxios.get(`/option/objectelement?object=${object}&element=${element}`);
    return res.data;
}

const getAll = async (object) => {
    const res = await instAxios.get(`/${object}/all`);
    return res.data;
}

const getSecureAll = async (object) => {
    const res = await instAxios.get(`/${object}/all`, { headers: authHeader() });
    return res.data;
}

const deleteRow = async (table, id) => {
    const res = await instAxios.delete(`/${table}/delete/${id}`, { headers: authHeader() });
    return res.data;
}

const getEmpByDept = async (dept) => {
    const res = await instAxios.get(`/employee/department?department=${dept}`);
    return res.data;
}

const getContent = async (id) => {
    const res = await instAxios.get(`/content/${id}`);
    return res.data;
}

const sendEmail = async (name, mobile, email, message) => {
    const response = await instAxios.post("/email/send", {
        name: name,
        mobile: mobile,
        email: email,
        message: message
    });
    return response;
};

const gs = {
    getById,
    getSecureByName,
    getByName,
    getByType,
    getBy,
    getListColumns,
    getFormColumns,
    getSections,
    getOptions,
    getAll,
    getSecureAll,
    deleteRow,
    getEmpByDept,
    getContent,
    sendEmail
}

export default gs;
