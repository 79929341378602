import React, { useEffect, useState } from 'react';
import SubHeader from "../components/SubHeader";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gs from '../services/gs';

const NEP = () => {
    const { t } = useTranslation();
    const [editorState, setEditorState] = useState();
    const [faculty, setFaculty] = useState();

    useEffect(() => {
        gs.getContent('290').then(res => {
            setEditorState(res.content);
        });
        gs.getByType('faculty','60').then(res => {
            setFaculty(res);
        });
    }, [])

    return (
        <div>
            <SubHeader />
            <div className="container my-4 faculty" >
                <h2 className="heading">National Education Policy 2020 (NEP-2020)</h2>
                <p className="-ta--j my-2">Under Graduate Programme Curriculum effective from Academic Year 2021-22 and Onwords</p>
                <ul style={{ paddingLeft: '0px' }}>
                    {faculty && faculty.map((each, index) => {
                        return (
                            <li key={index}><Link to={each.url}>{t(each.label)}</Link></li>
                        )
                    })}
                </ul>
                <div dangerouslySetInnerHTML={{ __html: editorState }} />
            </div>
        </div>
    );
}

export default NEP;