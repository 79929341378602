import React, { useEffect, useState } from 'react';
import SubHeader from "../components/SubHeader";
import gs from '../services/gs';
import { instAxios } from '../services/helper';
import EmpList from '../components/EmpList';

const USIC = () => {
    const deptId = 41;

    const [tab, setTab] = useState('overview');
    const [faculty, setFaculty] = useState();
    const [department, setDepartment] = useState();
    const [overviewCnt, setOverviewCnt] = useState();
    const [researchCnt, setResearchCnt] = useState();
    const [facilitiesCnt, setFacilitiesCnt] = useState();
    const [activitiesCnt, setActivitiesCnt] = useState();

    useEffect(() => {

        instAxios.get('/department/' + deptId).then(res => {
            setDepartment(res.data);
        });
    }, []);

    useEffect(() => {
        if (department !== undefined) {
            gs.getContent(department.overview).then(res => {
                setOverviewCnt(res.content);
            });
        }
    }, [department]);

    const onClickTab = (tab) => {
        setTab(tab);
        switch (tab) {
            case "research":
                gs.getContent(department.research).then(res => {
                    setResearchCnt(res.content);
                });
                break;
            case "faculty":
                instAxios.get('/employee/department?department=' + department.id).then(res => {
                    setFaculty(res.data);
                });
                break;
            case "facilities":
                gs.getContent(department.facilities).then(res => {
                    setFacilitiesCnt(res.content);
                });
                break;
            case "activities":
                gs.getContent(department.activities).then(res => {
                    setActivitiesCnt(res.content);
                });
                break;
            case "gallary":
                break;
            default:
                break;
        }
    }

    const tabs = [{
        label: "Overview",
        name: "overview"
    },
    {
        label: "Research",
        name: "research"
    },
    {
        label: "Faculty",
        name: "faculty"
    },
    {
        label: "Facilities",
        name: "facilities"
    },
    {
        label: "Extension Activities",
        name: "activities"
    },
    {
        label: "Gallary",
        name: "gallary"
    }];

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                {department && <h3 className="heading">{department.label}</h3>}
                <>
                    <ul className="nav nav-tabs">
                        {tabs.map((eachRow) => {
                            return (
                                <li key={eachRow.name} className="nav-item">
                                    <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                                </li>
                            )
                        })}
                    </ul>

                    <div className="tab-content">
                        {tab === 'overview' ? (
                            <div id="overview">
                                <div dangerouslySetInnerHTML={{ __html: overviewCnt }} />
                            </div>
                        ) : null}
                        {tab === 'research' ? (
                            <div id="research">
                                {researchCnt && <div dangerouslySetInnerHTML={{ __html: researchCnt }} />}
                            </div>
                        ) : null}
                        {tab === 'faculty' ? (
                            <div id="faculty">
                                {faculty ? (<EmpList data={faculty} />) : ("No data found")}
                            </div>
                        ) : null}
                        {tab === 'facilities' ? (
                            <div id="facilities">
                                {facilitiesCnt && <div dangerouslySetInnerHTML={{ __html: facilitiesCnt }} />}
                            </div>
                        ) : null}
                        {tab === 'activities' ? (
                            <div id="activities">
                                {activitiesCnt && <div dangerouslySetInnerHTML={{ __html: activitiesCnt }} />}
                            </div>
                        ) : null}
                    </div>
                </>
            </div>
        </div>
    );
}

export default USIC;