import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from "react-router-dom";
import AdminSideList from "../../components/AdminSideList";
import AuthService from "../../services/auth.service";
import gs from '../../services/gs';

const AdminLayout = () => {

    let navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState(undefined);
    const [objects, setObjects] = useState();

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
            //   setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
            //   setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        } else {
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        if (currentUser) {
            gs.getSecureAll('db_object').then(res => {
                setObjects(res);
            });
        }
    }, [currentUser]);

    function logout() {
        // remove user from local storage to log user out
        AuthService.logout();
        navigate("/");
    }

    return (
        <div>
            <nav className="header__content py-2">
                <div className="header__brand" onClick={() => logout()}>
                    <div className="logo-content" >
                        <div>
                            <img src='/images/logo.jpg' alt='logo' height={65} />
                        </div>
                        <div className="-ml--1 -mt--1 text-left">
                            <h3>GULBARGA UNIVERSITY ADMIN PANEL</h3>
                            <p>KALABURAGI - KARNATAKA - INDIA</p>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="row my-3">
                <div className="col-3 bg-light">
                    {currentUser && <AdminSideList data={objects} />}
                </div>
                <div className="col-9">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;