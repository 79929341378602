
import React, { useState } from 'react';

import SubHeader from "../components/SubHeader";

const PgCentres = () => {
    const [tab, setTab] = useState('aland');

    const onClickTab = (tab) => {
        setTab(tab);
    }

    const tabs = [{
        label: "Aland",
        name: "aland"
    },
    {
        label: "Bidar",
        name: "bidar"
    },
    {
        label: "Raichur",
        name: "raichur"
    }
];

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">PG Centres</h2>

                <ul className="nav nav-tabs">
                    {tabs.map((eachRow) => {
                        return (
                            <li key={eachRow.name} className="nav-item">
                                <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                            </li>
                        )
                    })}
                </ul>

                <div className="tab-content">
                    {tab === 'aland' ? (
                        <div id="aland">
                            <table className='light-table'>
                                <tbody>
                                    <tr>
                                        <td ><strong>Name of the Special Officer</strong></td>
                                        <td ><strong>Contact Number</strong></td>
                                        <td ><strong>Centre Place</strong></td>
                                    </tr>
                                    <tr>
                                        <td >Dr. M.S.Pasodi</td>
                                        <td>9448140078</td>
                                        <td >H.K.E. Society, Sri A.V.Patil College Aland-585302, Dist: Kalaburagi</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                            <div className='default-heading'>Courses</div>
                            <table className='light-table'>
                                <tbody>
                                    <tr>
                                        <td >1</td>
                                        <td >M.Com</td>
                                        <td >Master of Commerce</td>
                                    </tr>
                                    <tr>
                                        <td >2</td>
                                        <td>M.Sc.</td>
                                        <td >M.Sc. in Mathematics</td>
                                    </tr>
                                    <tr>
                                        <td >3</td>
                                        <td >M.A.</td>
                                        <td >M.A in English</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div id="aland">
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PgCentres;