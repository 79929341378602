import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import SubHeader from "../components/SubHeader";
import gs from '../services/gs';

const Academic = () => {
    const [posts, setPosts] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getListColumns('academic').then(res => {
            setColumns(res);
        });
        gs.getAll('academic').then(res => {
            setPosts(res);
        });
    }, []);

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h3 className="heading">The Academic Council</h3>
                <p>1. The Academic Council shall consist of the following members, namely:</p>
                <ul>
                    <li>The Vice-Chancellor.</li>
                    <li>The Commissioner for Collegiate Education or his nominee not below the rank of a Joint Director.</li>
                    <li>The Director of Technical Education or his nominee not below the rank of a Joint Director.</li>
                    <li>One Member of the Karnataka Legislative Assembly in respect of each district falling within the University area nominated by the Speaker Karnataka Legislative Assembly</li>
                    <li>Two Members of the Karnataka Legislative Council nominated by the Chairman, Karnataka Legislative Council</li>
                    <li>Ten Principals of affiliated colleges nominated by the Vice-Chancellor for a term of two years by rotation in the order of seniority</li>
                    <li>Three eminent persons representing Industry, commerce, banking or any other profession based within the territorial jurisdiction of the University nominated by the State Government for a term not exceeding three years</li>
                    <li>Five Professors of the Department of the University nominated by the Vice-Chancellor, by rotation in the order of seniority each for a term of two years</li>
                    <li>Five Deans of the faculties nominated by the Vice-Chancellor, by rotation in the order of seniority each for a term of two years.</li>
                    <li>Six students to be nominated by the Vice-Chancellor for a period of two years of whom One shall be a Student of a Degree Course, one, a Student of a Post-Graduate Course, one, a student of a Professional Course, one, a candidate of the National Cadet Corps, one, a candidate of a the National Service Scheme, one sports candidate on the basis of merit and of them so at-least one shall be a woman and one a Research Student.</li>
                    <li>Provided that no student shall be eligible for nomination:
                        <ul>
                            <li>Unless his name appears on the rolls of the University or a college affiliated to the University.</li>
                            <li>Unless he is following a course in the University or a college affiliated to the University leading to a degree or a Post-Graduate degree or Post-Graduate diploma of the University</li>
                            <li>If he is studying in a morning college or an evening college or having examination as an external candidate or through correspondence courses</li>
                            <li>If he has failed to complete a course in six years</li>
                        </ul>
                    </li>

                    <li>Six persons nominated by the State Government for a term of three years from amongst eminent educationists of whom one belonging to the Scheduled Castes or the Scheduled Tribes; one belonging the other Backward Classes, one representing the Women, one representing the religious minority and two representing others
                        <ul>
                            <li>The Librarian</li>
                            <li>The Director of Students Welfare</li>
                            <li>The Director of Planning, Monitoring and Evaluation Board</li>
                        </ul>
                    </li>
                </ul>
                <p>Provided that no person who is in the employment of an affiliated college or in the University in whatever capacity shall be eligible for nomination.</p>
                <p>2. The Academic Council shall meet at-least four times during an academic year, however that the duration between two Consecutive meetings shall not exceed three months.</p>

                <p className='default-heading'>Powers of the Academic Council</p>
                <p>1. The Academic Council shall be the Academic body the University and shall subject to the provisions of this Act, the Statutes, Ordinances and Regulations, have the control and general regulation of, and be responsible for the maintenance of, the standards of instruction, education and examination on the University</p>
                <p>2. Without prejudice to the generality of the foregoing and subject to such conditions as may be specified by or under the provisions of this Act, the Academic Council shall exercise the following powers, namely:</p>
                <ul>
                    <li>to make proposal for issue of Ordinances, relating to academic matters;</li>
                    <li>to make Regulations regarding the courses of study in so far as they are not covered by the ordinances;</li>
                    <li>to make Regulations regarding the scheme of examinations and conditions on which the students shall be admitted to the examinations, degrees, diplomas, certificates or other academic distinctions;</li>
                    <li>to make Regulation for declaration of the results of the various University Examinations;</li>
                    <li>to arrange for co-ordination of studies and of teaching in colleges and in recognised institutions;</li>
                    <li>to make proposals for allocating subjects to the Faculties and to assign its own members to the Faculties;</li>
                    <li>to determine the criteria for grant of exemptions relating to the admission of students to examinations;</li>
                    <li>to make proposals for the institution of posts of Professorships, Readerships, Lectureships and other posts of teachers required by the University and for prescribing the duties of such posts;</li>
                    <li>to make proposals for the institution and award of fellowships, travelling fellowships, scholarship, studentship, or exhibitions;</li>
                    <li>to make Regulations prescribing equivalence of examinations;</li>
                    <li>to make Regulations for granting exemptions from approved courses of study in the University or in affiliated colleges to qualify for degrees, diplomas and other academic distinctions;</li>
                    <li>to provide for instruction, teaching and training in such branches of learning and courses of study as may be appropriate for research for the advancement and dissemination of learning;</li>
                    <li>to make such provision as will enable the affiliated colleges and recognised institutions to undertake specialisation of studies;</li>
                    <li>to consider the annual financial estimates;</li>
                    <li>to amend or repeal any regulation;</li>
                    <li>to nominate Members to the various Authorities of the University;</li>
                    <li>to exercise such other powers and to perform such other duties as may be conferred or imposed on it by this Act or the Statutes, Ordinance or Regulations, made there under;</li>
                    <li>to establish and maintain departments of research and specialized studies and</li>
                    <li>generally to advise the University in all academic matters.</li>
                </ul>

                <ListView table="academic" data={posts} columns={columns} pageSize="30" title="Members" />
            </div>
        </div>
    );
}

export default Academic;