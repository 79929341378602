import React, { useEffect, useState } from 'react';

import Carousel from "../../components/Carousel";
import SectionLibrarian from '../../components/SectionLibrarian';
import NewsSectionLib from '../../components/NewsSectionLib';
import gs from '../../services/gs';

const LibraryHome = () => {

    const [cdata, setCdata] = useState();

    useEffect(() => {
        gs.getByType('carousel','48').then(res => {
            setCdata(res);
        });
    }, []);

    const captionStyle = {
        fontSize: "2em",
        fontWeight: "bold",
    };
    const slideNumberStyle = {
        fontSize: "20px",
        fontWeight: "bold",
    };

    return (
        <div>
            <Carousel
                data={cdata}
                time={3000}
                width="100%"
                height="520px"
                captionStyle={captionStyle}
                radius="1px"
                slideNumber={false}
                slideNumberStyle={slideNumberStyle}
                captionPosition="bottom"
                automatic={true}
                dots={true}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                thumbnails={false}
                thumbnailWidth="100px"
                showNavBtn={true}
                style={{
                    textAlign: "center"
                }}
            />
            <div>
                <div className="container-fluid py-5 section">
                    <SectionLibrarian />
                </div>

                <div className="container-fluid py-5">
                    <NewsSectionLib />
                </div>
                <div className="container-fluid section" data-aos="fade-up">
                    <div className='container card p-4'>
                        <div className="default-heading mb-2">Vision</div>
                        <p className="-ta--j mb-0">To provide the best possible information services to the users with its print and electronic resources to support academic and research activities in the networked environment!</p>
                    </div>
                    <br />
                    <div className='container card p-4'>
                        <div className="default-heading mb-2">Library Hours</div>
                        <p className="-ta--j mb-0">Working days : 8-00 AM to 7-00 PM <br />Holiday: 9-00 AM to 4-30 PM</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LibraryHome;