import React, { useState, useEffect } from "react";
import UploadService from "../services/FileUploadService";

const Upload = () => {

  const [folder, setFolder] = useState();
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");

  const [imageInfos, setImageInfos] = useState([]);

  const selectFile = (event) => {
    setCurrentFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
    setProgress(0);
    setMessage("");
  };

  const upload = () => {
    setProgress(0);

    UploadService.upload(folder, currentFile, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        setMessage(response.data.message);
        return UploadService.getFiles();
      })
      .then((files) => {
        setImageInfos(files.data);
      })
      .catch((err) => {
        setProgress(0);

        if (err.response && err.response.data && err.response.data.message) {
          setMessage(err.response.data.message);
        } else {
          setMessage("Could not upload the Image!");
        }

        setCurrentFile(undefined);
      });
  };

  useEffect(() => {
    UploadService.getFiles().then((response) => {
      setImageInfos(response.data);
    });
  }, []);

  const options = [
    {
      label: "Notifiation/Exam/UG",
      value: "notification/exam/ug",
    },
    {
      label: "Notifiation/Exam/PG",
      value: "notification/exam/pg",
    },
    {
      label: "Syllabus",
      value: "syllabus",
    },
    {
      label: "Department",
      value: "department",
    },
    {
      label: "Employee",
      value: "employee",
    },
    {
      label: "Carousel",
      value: "carousel",
    },
    {
      label: "Others",
      value: "others",
    }
  ]
  return (
    <div className="container">
      <h4 className="heading">Upload</h4>
      <div className="row">
        <div className="col-2">
          <select className="form-select mb-2" aria-label="Default select example" onChange={(e) => setFolder(e.target.value)} >
            <option value="0">Select Folder</option>
            {options.map((eachRow, index) => {
              return (
                <option key={index} value={eachRow.value}>{eachRow.label}</option>
              )
            })}
          </select>
        </div>
        <div className="col-6">
          <label className="btn btn-default p-0">
            <input type="file" multiple onChange={selectFile} />
          </label>
        </div>

        <div className="col-4">
          <button
            className="btn btn-success btn-sm"
            disabled={!currentFile}
            onClick={upload}
          >
            Upload
          </button>
        </div>
      </div>

      {currentFile && (
        <div className="progress my-3">
          <div
            className="progress-bar progress-bar-info"
            role="progressbar"
            aria-valuenow={progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progress + "%" }}
          >
            {progress}%
          </div>
        </div>
      )}

      {previewImage && (
        <div>
          <img className="preview" src={previewImage} alt="" />
        </div>
      )}

      {message && (
        <div className="alert alert-secondary mt-3" role="alert">
          {message}
        </div>
      )}

      <div className="mt-3">
        <div className="card-header">List of files</div>
        <ul className="list-group list-group-flush">
          {imageInfos &&
            imageInfos.map((img, index) => (
              <li className="list-group-item" key={index}>
                <p>
                  <a href={img.url}>{img}</a>
                </p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Upload;