import SubBanner from "../../components/SubHeader";

const ProjectsMou = () => {
    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">Projects & MoU</h3>
                <p className="-ta--j">The University Library has been striving to get research projects from funding agencies at regular intervals and the details of research projects availed for the developmental of library system are detailed.</p>
                <table className="light-table">
                    <thead className="fw-bold">
                        <tr>
                            <td>Sl.No.</td>
                            <td>Major Research projects</td>
                            <td>Year</td>
                            <td>Amount of Grant</td>
                            <td>Support</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1.</td>
                            <td>Virtual Learning Resource Centre and Digital Library</td>
                            <td>2007-2008</td>
                            <td>1 Crore</td>
                            <td>Infosys Foundation, Bengaluru</td>
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td>Development of E-Content on Traditional Knowledge and Folk Wisdom of Hyderabad Karnataka Region</td>
                            <td>2010-2012</td>
                            <td>6.8 Lakh</td>
                            <td>UGC, New Delhi</td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>Use of Information sources and service among seven universities of Karnataka in Social sciences - Major Research Project</td>
                            <td>2012-2013</td>
                            <td>8.5 lakh</td>
                            <td>UGC, New Delhi</td>
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td>Science Education e-Library - Digital Library and Information Centre - Macro Project</td>
                            <td>2015-2016</td>
                            <td>68.73 lakh</td>
                            <td>KKRDB, Kalaburagi (Govt. of Karnataka)</td>
                        </tr>
                        <tr>
                            <td>5.</td>
                            <td>Digital Knowledge Project - E.Content &amp; Training to 1000 Gram Panchayat Librarians of six districts in Kalyan (Hyderabad) Karnataka Region</td>
                            <td>2015-2016</td>
                            <td>1 Crore</td>
                            <td>KKRDB, Kalaburagi (Govt. of Karnataka)</td>
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td>Open Air Green Library with RFID Techno-enabled Library for effective Retrieval, Stock Control and Security Compliance</td>
                            <td>2019-2021</td>
                            <td>53 lakh</td>
                            <td>KKRDB, Kalaburagi (Govt. of Karnataka)</td>
                        </tr>
                        <tr>
                            <td>7.</td>
                            <td>Upgradation of ICT Infrastructure in Virtual Learning Resource Centre &amp; Digital Library</td>
                            <td>2020-2021</td>
                            <td>1 Crore</td>
                            <td>Kalyan Karnataka Human Resources, Agriculture &amp; Cultural Association (Govt. of Karnataka)</td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                <p className="-ta--j">The library is institutional member of INFLIBNET Centre (An IUC of UGC), Developing Library Network (DELNET), National Digital Library of India (NDLI) and e-Sarvajanika Granthalaya, Department of Public Libraries for sharing resources. The Library signed Memorandum of Understanding with INFLIBNET Centre includes Shodhchakra and Developing Library Network (DELNET) for sharing and accessing e.resources.</p>
                <div className="default-heading">MoU with Degree colleges affiliated to Gulbarga University</div>
                <p className="-ta--j">The Degree colleges affiliated to Gulbarga University demand for MoU and access to remote access facility to the electronic resources of Gulbarga University especially for their NAAC activities and uploading for SSR. In the digital environ, access to information irrespective of location is essential and the Gulbarga University Library has been providing access to e-resources using remote access facility to the faculty and students of Gulbarga University and there is a need to extend these e.resources to the affiliated degree colleges of Gulbarga University as it is demanded by the college librarians.  In fact, Outreach Extension Activity for Affiliated Degree Colleges of Gulbarga University (Government and Private Aided Degree Colleges) as a Social Responsibility to reach user's with Information to support NAAC Criteria 3 for Research, Innovations and Extension. The University Library extends Remote Access Facility to the Government Degree Colleges and Private Aided Degree Colleges affiliated to Gulbarga University and support for the development of college libraries.</p>
            </div>
        </>
    )
}

export default ProjectsMou;