import React, { useEffect, useState } from 'react';
import ListView from '../../components/ListView';
import SubBanner from "../../components/SubHeader";
import gs from '../../services/gs';

const LibraryBulletin = () => {
    const [columns, setColumns] = useState();
    const [newAdd, setNewAdd] = useState();
    const [newsLetter, setNewsLetter] = useState();
    const [newsExpress, setNewsExpress] = useState();

    useEffect(() => {
        gs.getListColumns('implink').then(res => {
            setColumns(res);
        });
        gs.getByType('implink', '44').then(res => {
            setNewAdd(res);
        });
        gs.getByType('implink', '45').then(res => {
            setNewsLetter(res);
        });
        gs.getByType('implink', '102').then(res => {
            setNewsExpress(res);
        });
    }, []);

    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">Library Bulletin</h3>
                <p className="-ta--j">The Library as a means to keep the users informed about the new books added to the library for every six months through Current Awareness Service – Newly added books in the Library. It also brings Library Newsletter incorporating the library activities, programs and initiatives taken.</p>
                <div className="heading default-heading" style={{ marginTop: '20px' }}>New Additions (Books) - Current Awareness</div>
                {newAdd ? (<ListView table="implink" data={newAdd} columns={columns} title="" pageSize="10" />) : ("No data found")}
                <div className="heading default-heading" style={{ marginTop: '25px' }}>Library Newsletter</div>
                {newsLetter ? (<ListView table="implink" data={newsLetter} columns={columns} title="" pageSize="10" />) : ("No data found")}
                <div className="heading default-heading" style={{ marginTop: '25px' }}>University News Express</div>
                {newsExpress ? (<ListView table="implink" data={newsExpress} columns={columns} title="" pageSize="10" />) : ("No data found")}
            </div>
        </>
    )
}

export default LibraryBulletin;