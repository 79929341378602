import React, { useEffect, useState } from 'react';
import SubHeader from "../components/SubHeader";
import gs from '../services/gs';

const Implink = () => {
    const [posts, setPosts] = useState();

    useEffect(() => {
        gs.getByType('implink','33').then(res => {
            setPosts(res);
        });
    }, []);

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Important Links</h2>
                <ul>
                    {posts && posts.map((eachRow) => {
                        return (
                            <li className='implink' key={eachRow.id}>
                                <a href={eachRow.url} target="_blank" rel="noreferrer" >{eachRow.title}</a>
                            </li>)
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Implink;