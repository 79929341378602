import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gs from '../services/gs';

const AboutSectionUni = () => {
    const [content, setContent] = useState();

    useEffect(() => {
        gs.getContent('119').then(res => {
            setContent(res.content);
        });
    }, []);

    return (
        <div className="container" data-aos="fade-up" >
            <h2 className="heading">About Our University</h2>
            <div className="flex-container">
                <div className="text-child-container">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                    <Link className="read-more-btn" data-aos="fade-right" to="/page/about-us">
                        Read More
                    </Link>
                </div>
                <div className="img-child-container">
                    <img src="/images/01.jpg" className="img-fluid w-100" alt="about-img" />
                </div>
            </div>
        </div>
    );
}

export default AboutSectionUni;