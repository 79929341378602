import SubBanner from "../../components/SubHeader";

const DeptLibraries = () => {
    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">Departmental Libraries</h3>
            </div>
        </>
    )
}

export default DeptLibraries;