import SubBanner from "../../components/SubHeader";
const LibrarianMsg = () => {
    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />

            <div className="container my-4">
                <h2 className="heading">Librarian's Message</h2>
                <div className="row">
                    <div className="col-8">
                        <p className="-ta--j">Gulbarga University is one of the leading centers of academic excellence caters to the educational needs of the society located in the developing region of Kalyan Karnataka established in 10-09-1980, has a sprawling green campus of over 800 acres offering . Gulbarga University Library, a Knowledge Centre for accessibility developed on modern lines as a prominent Learning Resource Centre on sprawling Campus with Open Air Green Library, ideally situated and easily accessible by all the departments and students housing with a carpet area of 7,525 Sq. Meters with seating capacity of 800 users. The University Library, makes sincere efforts to provide user focused services having over 2.5 lakh books, subscribes to 2500 scientific print & online journals, 5400 e-books, sixteen CD-ROM Archival.</p>
                    </div>
                    <div className="col-4" style={{ textAlign: 'center' }}>
                        <img src="/images/suresh_jange.jpg" className="w-100 img-fluid my-2" alt="Vice Chancellor" />
                        <br />
                        <span className="default-heading">Dr. Suresh Jange</span>, University Librarian
                    </div>
                </div>
                <p className="-ta--j"> More than 1100 Ph.D e-Theses, 7200+ printed dissertation/theses and technical reports, microfilm, microfiche, conference papers and rare books. The Library is at the core of research and learning at the University and automated its in-house activities and services using KOHA – integrated open source software using cloud technology and implemented RFID Technology. The Library has been regularly contributing its Biblio-records to IndCat, and PhD Thesis at Shodhganga@INFLIBNET Centre. Virtual Learning Resource Center and Digital Library (VLRC & DL), with 140 computer systems and three main servers, a giant leap into information age is at the heart of the University Learning experience supporting e-LTR (e-Learning Teaching and Research), provides access to e-ShodhSindu: Consortia e-resources (10,000 e-journals+ & databases), Internet and e-learning, course materials and e-books using one GBPS leased line connectivity under NMEICT Project. Remote access facility using Knimbus and Mobile App, Institutional Repository, QR Code, Library Bulletin and Current Awareness (New Additions) and Career Planning and User Sensitization Programs are a few unique services rendered to promote the optimum use of library resources. Wi-Fi (Wireless Fidelity) access is available across the library. The library is institutional member of INFLIBNET Centre (An IUC of UGC), Developing Library Network (DELNET), National Digital Library of India (NDLI) and e-Sarvajanika Granthalaya, Department of Public Libraries for resource sharing and network based learning.</p>
                <p className="-ta--j">The library received Dr. Padhya Best University Library Award for 2007 instituted by Indian Library Association, New Delhi and recipient of “eINDIA Award 2011” for the Best ICT Enabled University at India’s Largest ICT Event during December 2011. Best KOHA Implementation Award @ Tata Consultancy Service, Pune during 2017 and nominated for Informatics Innovation Award for Open Air Green Library with Wi-Fi, e-books and journal collection accessible through Computers, Tabs and Kindles during Library Technology Conclave (LTC-2021).</p>
                <p className="-ta--j">University Library has a vision to make it as a vibrant Centre to provide the best possible information services to the user community with its rich print and online resources to support the academics, research and developmental activities.</p>
                <div className="default-heading">Dr. Suresh Jange</div>
                University Librarian
            </div>
        </>
    )
}

export default LibrarianMsg;