import SubHeader from "../components/SubHeader";
import React, { useEffect, useState } from 'react';
import _ from "lodash";
import TabAY from "../components/TabAY";
import gs from "../services/gs";
import { instAxios } from "../services/helper";

const CalendarEvents = () => {
    const [tab, setTab] = useState('ug');
    const [columns, setColumns] = useState();

    const [ug4, setUg4] = useState();
    const [ug3, setUg3] = useState();
    const [ug2, setUg2] = useState();
    const [ug1, setUg1] = useState();

    const [pg4, setPg4] = useState();
    const [pg3, setPg3] = useState();
    const [pg2, setPg2] = useState();
    const [pg1, setPg1] = useState();

    const [phd4, setPhd4] = useState();
    const [phd3, setPhd3] = useState();
    const [phd2, setPhd2] = useState();
    const [phd1, setPhd1] = useState();

    useEffect(() => {
        gs.getListColumns('cevent').then(res => {
            setColumns(res);
        });
        instAxios.get('cevent/course?course=24').then(res => {
            setUg4(_.filter(res.data, ['ayear.value', '100']));
            setUg3(_.filter(res.data, ['ayear.value', '93']));
            setUg2(_.filter(res.data, ['ayear.value', '28']));
            setUg1(_.filter(res.data, ['ayear.value', '27']));
        });
        instAxios.get('cevent/course?course=25').then(res => {
            setPg4(_.filter(res.data, ['ayear.value', '100']));
            setPg3(_.filter(res.data, ['ayear.value', '93']));
            setPg2(_.filter(res.data, ['ayear.value', '28']));
            setPg1(_.filter(res.data, ['ayear.value', '27']));
        });
        instAxios.get('cevent/course?course=26').then(res => {
            setPhd4(_.filter(res.data, ['ayear.value', '100']));
            setPhd3(_.filter(res.data, ['ayear.value', '93']));
            setPhd2(_.filter(res.data, ['ayear.value', '28']));
            setPhd1(_.filter(res.data, ['ayear.value', '27']));
        });
    }, []);

    const onClickTab = (tab) => {
        setTab(tab);
    }

    const tabs = [{
        label: "Under-Graduate (UG)",
        name: "ug"
    },
    {
        label: "Post-Graduate (PG)",
        name: "pg"
    },
    {
        label: "M.Phil-Ph.D.",
        name: "phd"
    }];

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Calendar of Events</h2>
                <ul className="nav nav-tabs">
                    {tabs.map((eachRow) =>
                        <li key={eachRow.name} className="nav-item">
                            <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                        </li>
                    )}
                </ul>

                <div className="tab-content">
                    {tab === 'ug' ? (<TabAY columns={columns} data24={ug4} data23={ug3} data22={ug2} data21={ug1} />) : null}
                    {tab === 'pg' ? (<TabAY columns={columns} data24={pg4} data23={pg3} data22={pg2} data21={pg1} />) : null}
                    {tab === 'phd' ? (<TabAY columns={columns} data24={phd4} data23={phd3} data22={phd2} data21={phd1} />) : null}
                </div>
            </div>
        </div>
    );
}

export default CalendarEvents;