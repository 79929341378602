import { Link } from "react-router-dom";

const Card = ({ data }) => {
    return (
        <Link className="card m-2" style={{width: '9rem'}} to={data.url}>
            <img src={data.image} className="card-img-top" alt="..." />
            <div className="card-body">
                <h5 className="card-title" dangerouslySetInnerHTML={{ __html: data.title }}></h5>
                {/* <p className="card-text">{data.subtitle}</p> */}
                {/* <a href="#" className="btn btn-primary">Show more</a> */}
            </div>
        </Link>
    );
};

export default Card;
