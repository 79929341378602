import { instAxios } from "./helper"
import authHeader from "./auth-header";

export const ApiServiceCreate = async (data, table) => {
    const response = await instAxios.post('/' + table + '/create', data, { headers: authHeader() });
    return response.data;
}

export const ApiServiceUpdate = async (data, table) => {
    const response = await instAxios.put('/' + table + '/update', data, { headers: authHeader() });
    return response.data;
}

export const ApiServiceDelete = async (data, table) => {
    const response = await instAxios.delete('/' + table + '/delete/' + data, { headers: authHeader() });
    return response.data;
}

const getToken = () => {
    return localStorage.getItem('USER_KEY');
}

export const userLogin = async (authRequest) => {
    const response = await instAxios.post('/auth/login', authRequest);
    return response.data;
}

export const fetchUserData = async (authRequest) => {
    const response = await instAxios.get('/auth/login', authRequest, {
        'Authorization': 'Bearer ' + getToken()
    });
    return response.data;
}