import SubHeader from "../components/SubHeader";

const Prospectus = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Prospectus</h2>
                <div className="default-heading">Will be updated shortly</div>
            </div>
        </div>
    );
}

export default Prospectus;