import React, { useEffect, useState } from 'react';
import SubHeader from "../components/SubHeader";
import gs from '../services/gs';

const StatutoryOfficers = () => {
    const [officer, setOfficer] = useState();

    useEffect(() => {
        gs.getByType('employee','53').then(res => {
            setOfficer(res);
        });
    }, []);

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Statutory Officers</h2>
                <table className='light-table'>
                    <thead>
                        <tr className='bg-light'>
                            <td className='col-4 fw-bold'>Officer</td>
                            <td className='col-4 fw-bold'>Contact Number</td>
                            <td className='col-4 fw-bold'>Email Id</td>
                        </tr>
                    </thead>
                    <tbody>
                        {officer && officer.map((eachRow, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {eachRow.name}<br />
                                        {eachRow.designation.label}
                                    </td>
                                    <td>{eachRow.contactNumber}</td>
                                    <td>{eachRow.emailId}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default StatutoryOfficers;