import React, { useEffect, useState } from 'react';
import ListView from '../../components/ListView';
import SubBanner from "../../components/SubHeader";
import gs from '../../services/gs';

const UsefulLinks = () => {

    const [columns, setColumns] = useState();
    const [quickLinks, setQuickLinks] = useState();
    const [govtweb, setGovtweb] = useState();
    const [eduweb, setEduweb] = useState();
    const [study, setStudy] = useState();
    const [ors, setOrs] = useState();
    const [openAccess, setOpenAccess] = useState();
    const [career, setCareer] = useState();
    const [fellowships, setFellowships] = useState();
    const [rp, setRp] = useState();
    const [jv, setJv] = useState();
    const [hs, setHs] = useState();

    useEffect(() => {

        gs.getListColumns('implink').then(res => {
            setColumns(res);
        });
        gs.getByType('implink', '51').then(res => {
            setQuickLinks(res);
        });
        gs.getByType('implink', '34').then(res => {
            setGovtweb(res);
        });
        gs.getByType('implink', '35').then(res => {
            setEduweb(res);
        });
        gs.getByType('implink', '36').then(res => {
            setStudy(res);
        });
        gs.getByType('implink', '37').then(res => {
            setOrs(res);
        });
        gs.getByType('implink', '38').then(res => {
            setOpenAccess(res);
        });
        gs.getByType('implink', '39').then(res => {
            setCareer(res);
        });
        gs.getByType('implink', '40').then(res => {
            setFellowships(res);
        });
        gs.getByType('implink', '41').then(res => {
            setRp(res);
        });
        gs.getByType('implink', '42').then(res => {
            setJv(res);
        });
        gs.getByType('implink', '43').then(res => {
            setHs(res);
        });

    }, []);

    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h4 className="heading">Useful Links</h4>

                {quickLinks ? (<ListView table="implink" data={quickLinks} columns={columns} title="Quick Links" pageSize="10" />) : ("No data found")}
                {govtweb ? (<ListView table="implink" data={govtweb} columns={columns} title="Government Websites" pageSize="10" />) : ("No data found")}
                {eduweb ? (<ListView table="implink" data={eduweb} columns={columns} title="Educational Websites" pageSize="10" />) : ("No data found")}
                {study ? (<ListView table="implink" data={study} columns={columns} title="Study Notes, Learning Resources and Course Materials" pageSize="10" />) : ("No data found")}
                {ors ? (<ListView table="implink" data={ors} columns={columns} title="Online Reference Sources" pageSize="10" />) : ("No data found")}
                {openAccess ? (<ListView table="implink" data={openAccess} columns={columns} title="Open Access/ Free E-Books/ E-Journals/ Subject Databases" pageSize="10" />) : ("No data found")}
                {career ? (<ListView table="implink" data={career} columns={columns} title="Career Information Resources" pageSize="10" />) : ("No data found")}
                {fellowships ? (<ListView table="implink" data={fellowships} columns={columns} title="Fellowships" pageSize="10" />) : ("No data found")}
                {rp ? (<ListView table="implink" data={rp} columns={columns} title="Research Projects" pageSize="10" />) : ("No data found")}
                {jv ? (<ListView table="implink" data={jv} columns={columns} title="Job Vacancies" pageSize="10" />) : ("No data found")}
                {hs ? (<ListView table="implink" data={hs} columns={columns} title="Higher Studies" pageSize="10" />) : ("No data found")}
            </div>
        </>
    )
}

export default UsefulLinks;

