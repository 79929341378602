
import React, { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { getMenuObject } from '../../services/helper';
import { useTranslation } from "react-i18next";
import MegaMenu from '../../components/MegaMenu';
import gs from '../../services/gs';

const LibraryLayout = () => {

    const { t } = useTranslation();

    const [data, setData] = useState();
    const [menu, setMenu] = useState();
    const [megaMenu, setMegaMenu] = useState();

    useEffect(() => {
        gs.getByType('menu','55').then(res => {
            setData(res);
        });
        gs.getByType('menu','57').then(res => {
            setMegaMenu(res);
        });
    }, []);

    useEffect(() => {
        if (data !== undefined) {
            setMenu(getMenuObject(data));
        }
    }, [data]);

    return (
        <div>
            <nav className="header__toolbar">
                <div className="header__start">
                    <MegaMenu data={megaMenu} />
                </div>
            </nav>
            <nav className="header__content py-2">
                <div className="header__brand">
                    <Link className="logo-content" to="/">
                        <div>
                            <img src='/images/logo.jpg' alt='logo' height={65} />
                        </div>
                        <div className="-ml--1 -mt--1 text-left">
                            <h3>{t("gulbarga-university")} {t("library")}</h3>
                            <p>{t("kalaburagi")} - {t("karnataka")} - {t("india")}</p>
                        </div>
                    </Link>
                </div>
                <div className="header__end">
                    <div className="-d--none -d-lg--flex">
                        <Navbar data={menu} />
                    </div>
                </div>
            </nav>
            <Outlet />
        </div>
    );
}

export default LibraryLayout;