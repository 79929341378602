import { Link } from "react-router-dom";

const AdminHome = () => {
    return (
        <div className="row">
            <h4 className="heading">Admin Panel</h4>
            <div className="card m-4" style={{ width: '12rem', height: '10rem' }}>
                <div className="default-heading">05</div>
                Faculties
            </div>
            <Link to="list/department" className="card m-4" style={{ width: '12rem', height: '10rem' }}>
                <div className="default-heading">32</div>
                Departments
            </Link>
            <div className="card m-4" style={{ width: '12rem', height: '10rem' }}>
                <div className="default-heading">120</div>
                Teaching
            </div>
            <div className="card m-4" style={{ width: '12rem', height: '10rem' }}>
                <div className="default-heading">180</div>
                Non-teaching
            </div>
        </div>
    )
}

export default AdminHome;