import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import gs from '../services/gs';
import SubHeader from "../components/SubHeader";

const Syllabus = () => {
    const [tab, setTab] = useState('ug');
    const [insedeTab, setInsedeTab] = useState('ba');
    const [ba, setBa] = useState();
    const [bed, setBed] = useState();
    const [bbm, setBbm] = useState();
    const [bca, setBca] = useState();
    const [bcom, setBcom] = useState();
    const [bsc, setBsc] = useState();
    const [bva, setBva] = useState();
    const [ae, setAe] = useState();
    const [pg, setPg] = useState();
    const [mPhilPhD, setMPhilPhD] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getListColumns('syllabus').then(res => {
            setColumns(res);
        });
        gs.getBy('syllabus', 'course', '15').then(res => {
            setBa(res);
        });
    }, []);

    const onClickTab = (tab) => {
        setTab(tab);
        switch (tab) {
            case "ug":
                gs.getBy('syllabus', 'course', '15').then(res => {
                    setBa(res);
                });
                break;
            case "pg":
                gs.getBy('syllabus', 'course', '23').then(res => {
                    setPg(res);
                });
                break;
            case "mphilphd":
                gs.getBy('syllabus', 'type', '103').then(res => {
                    setMPhilPhD(res);
                });
                break;
            default:
                break;
        }
    }

    const onClickInsideTab = (tab) => {
        setInsedeTab(tab);
        switch (tab) {
            case "ba":
                gs.getBy('syllabus', 'course', '15').then(res => {
                    setBa(res);
                });
                break;
            case "bed":
                gs.getBy('syllabus', 'course', '16').then(res => {
                    setBed(res);
                });
                break;
            case "bbm":
                gs.getBy('syllabus', 'course', '17').then(res => {
                    setBbm(res);
                });
                break;
            case "bca":
                gs.getBy('syllabus', 'course', '18').then(res => {
                    setBca(res);
                });
                break;
            case "bcom":
                gs.getBy('syllabus', 'course', '19').then(res => {
                    setBcom(res);
                });
                break;
            case "bsc":
                gs.getBy('syllabus', 'course', '20').then(res => {
                    setBsc(res);
                });
                break;
            case "bva":
                gs.getBy('syllabus', 'course', '21').then(res => {
                    setBva(res);
                });
                break;
            case "ae":
                gs.getBy('syllabus', 'course', '22').then(res => {
                    setAe(res);
                });
                break;
            default:
                break;
        }
    }

    const tabs = [{
        label: "UG",
        name: "ug"
    },
    {
        label: "PG",
        name: "pg"
    },
    {
        label: "MPhil/PhD Course Work",
        name: "mphilphd"
    },];

    const insedeTabs = [{
        label: "BA",
        name: "ba"
    },
    {
        label: "BEd",
        name: "bed"
    },
    {
        label: "BBM",
        name: "bbm"
    },
    {
        label: "BCA",
        name: "bca"
    },
    {
        label: "BCom",
        name: "bcom"
    },
    {
        label: "BSc",
        name: "bsc"
    },
    {
        label: "BVA",
        name: "bva"
    },
    {
        label: "Ability Enhancement",
        name: "ae"
    }];

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Syllabus</h2>

                <ul className="nav nav-tabs">
                    {tabs.map((eachTab) => {
                        return (
                            <li key={eachTab.name} className="nav-item">
                                <a className={tab === eachTab.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachTab.name)}>{eachTab.label}</a>
                            </li>
                        )
                    })}
                </ul>

                <div className="tab-content">
{/* 
                    {tab === 'ug' ? (
                        <div id="ug">
                            <ul className="nav nav-tabs">
                                {insedeTabs.map((eachRow) => {
                                    return (
                                        <li key={eachRow.name} className="nav-item">
                                            <a className={insedeTab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickInsideTab(eachRow.name)}>{eachRow.label}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                            <div className="tab-content">
                                {insedeTab === 'ba' ? (
                                    <div id='ba'>
                                        {ba ? (<ListView table="syllabus" data={ba} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                    </div>
                                ) : null}
                                {insedeTab === 'bed' ? (
                                    <div id='bed'>
                                        {bed ? (<ListView table="syllabus" data={bed} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                    </div>
                                ) : null}
                                {insedeTab === 'bbm' ? (
                                    <div id='bbm'>
                                        {bbm ? (<ListView table="syllabus" data={bbm} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                    </div>
                                ) : null}
                                {insedeTab === 'bca' ? (
                                    <div id='bca'>
                                        {bca ? (<ListView table="syllabus" data={bca} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                    </div>
                                ) : null}
                                {insedeTab === 'bcom' ? (
                                    <div id='bcom'>
                                        {bca ? (<ListView table="syllabus" data={bcom} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                    </div>
                                ) : null}
                                {insedeTab === 'bsc' ? (
                                    <div id='bsc'>
                                        {bsc ? (<ListView table="syllabus" data={bsc} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                    </div>
                                ) : null}
                                {insedeTab === 'bva' ? (
                                    <div id='bva'>
                                        {bva ? (<ListView table="syllabus" data={bva} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                    </div>
                                ) : null}
                                {insedeTab === 'ae' ? (
                                    <div id='ae'>
                                        {ae ? (<ListView table="syllabus" data={ae} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div id="pg">
                            {tab === 'pg' ? (
                                <div id='pg'>
                                    {pg ? (<ListView table="syllabus" data={pg} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                </div>
                            ) : null}
                        </div>
                    )} */}

                    {(() => {
                        switch (tab) {
                            case 'ug':
                                return (
                                    <div id="ug">
                                        <ul className="nav nav-tabs">
                                            {insedeTabs.map((eachRow) => {
                                                return (
                                                    <li key={eachRow.name} className="nav-item">
                                                        <a className={insedeTab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickInsideTab(eachRow.name)}>{eachRow.label}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <div className="tab-content">
                                            {insedeTab === 'ba' ? (
                                                <div id='ba'>
                                                    {ba ? (<ListView table="syllabus" data={ba} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                                </div>
                                            ) : null}
                                            {insedeTab === 'bed' ? (
                                                <div id='bed'>
                                                    {bed ? (<ListView table="syllabus" data={bed} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                                </div>
                                            ) : null}
                                            {insedeTab === 'bbm' ? (
                                                <div id='bbm'>
                                                    {bbm ? (<ListView table="syllabus" data={bbm} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                                </div>
                                            ) : null}
                                            {insedeTab === 'bca' ? (
                                                <div id='bca'>
                                                    {bca ? (<ListView table="syllabus" data={bca} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                                </div>
                                            ) : null}
                                            {insedeTab === 'bcom' ? (
                                                <div id='bcom'>
                                                    {bca ? (<ListView table="syllabus" data={bcom} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                                </div>
                                            ) : null}
                                            {insedeTab === 'bsc' ? (
                                                <div id='bsc'>
                                                    {bsc ? (<ListView table="syllabus" data={bsc} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                                </div>
                                            ) : null}
                                            {insedeTab === 'bva' ? (
                                                <div id='bva'>
                                                    {bva ? (<ListView table="syllabus" data={bva} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                                </div>
                                            ) : null}
                                            {insedeTab === 'ae' ? (
                                                <div id='ae'>
                                                    {ae ? (<ListView table="syllabus" data={ae} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                )
                            case 'pg':
                                return (<div id='pg'>
                                    {pg ? (<ListView table="syllabus" data={pg} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                </div>)
                            case 'mphilphd':
                                return (<div id='mphilphd'>
                                    {mPhilPhD ? (<ListView table="syllabus" data={mPhilPhD} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                                </div>)
                        }
                    })()}
                </div>
            </div>
        </div>
    );
}

export default Syllabus;