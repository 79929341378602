import React, { useEffect, useState } from 'react';
import ListView from '../../components/ListView';
import SubBanner from "../../components/SubHeader";
import gs from '../../services/gs';

const Collections = () => {
    const [columns, setColumns] = useState();
    const [eBooks, setEBooks] = useState();
    const [eJournals, setEJournals] = useState();
    const [linkColumns, setLinkColumns] = useState();
    const [aps, setAps] = useState(); //Anti-Plagiarism Software - 86
    const [rama, setRama] = useState(); //Remote Access & Mobile App - 87
    const [imln, setImln] = useState(); //Institutional Membership to Library Networks  - 88

    useEffect(() => {
        
        gs.getListColumns('implinkqty').then(res => {
            setColumns(res);
        });
        gs.getByType('implinkqty','70').then(res => {
            setEBooks(res);
        });
        gs.getByType('implinkqty','71').then(res => {
            setEJournals(res);
        });
        gs.getListColumns('implink').then(res => {
            setLinkColumns(res);
        });
        gs.getByType('implink','86').then(res => {
            setAps(res);
        });
        gs.getByType('implink','87').then(res => {
            setRama(res);
        });
        gs.getByType('implink','88').then(res => {
            setImln(res);
        });
    }, []);

    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">Collections</h3>
                <img className="my-3" src="/images/library/vision.jpg" alt="Hostels" />
                <p className="-ta--j">The Library is a Knowledge resource centre comprising of variety of information sources that includes General Reading books, Reference Books, Text Books, Competitive Exam Books, SC/ST Book Bank Books and special collection Books on Jnana peetha awardees, national Leaders and Peethas of the university. The Library has various units/ sections with a responsibility to develop collections, processing, organizing and mechanism to deliver with innovative services and facilities.</p>
                <img className="my-2" src="/images/library/collection.jpg" alt="Hostels" />
                <div className="default-heading">Library Collections at a Glance</div>
                <table className="light-table mt-1">
                    <thead>
                        <tr>
                            <td colSpan="2" className="fw-bold">Information Resources</td>
                            <td colSpan="2" className="fw-bold">ICT Infrastructure</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="col-4">Books</td>
                            <td className="col-1"></td>
                            <td className="col-4">1. Desktops</td>
                            <td className="col-1">140</td>
                        </tr>
                        <tr>
                            <td>• Main Campus, Kalaburagi</td>
                            <td>2,65,076</td>
                            <td>2. Main Servers</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>• Study Centre, Basavakalyan</td>
                            <td>10,521</td>
                            <td>3. Smart Boards</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>• PG Centre, Aland</td>
                            <td>1,150</td>
                            <td>4. Kindle E-Tabs</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>SCIENTIFIC JOURNALS (Subscribed Print & Online)</td>
                            <td>2,075</td>
                            <td>5. Information Kiosk</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>BACK VOLUMES OF PERIODICALS</td>
                            <td>22,145</td>
                            <td>6. RFID (Gates + Workstation)</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>MANUSCRIPTS (KANNADA DEPT.)</td>
                            <td>5,500</td>
                            <td>7. Scanners</td>
                            <td>3</td>
                        </tr>
                        <tr>
                            <td>MICROFICHE / MICROFILMS</td>
                            <td>2,042</td>
                            <td>8. LCD Projector</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>THESIS AND DISSERTATIONS</td>
                            <td>6,927</td>
                            <td>9. Video Conferencing</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>ONLINE RESOURCES</td>
                            <td></td>
                            <td>REGISTERED USERS</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>• Thesis Online (Shodhganga)</td>
                            <td>1180</td>
                            <td>• PG Students</td>
                            <td>2850</td>
                        </tr>
                        <tr>
                            <td>• E-Journals & Databases (e-Shodhsindhu)</td>
                            <td>10,000+</td>
                            <td>• Research Scholars</td>
                            <td>315</td>
                        </tr>
                        <tr>
                            <td>• E-Books (Springer, ProQuest, WTG)</td>
                            <td>4,200</td>
                            <td>• Faculty & other staff</td>
                            <td>280</td>
                        </tr>
                        <tr>
                            <td>• E-Books (Kannada)</td>
                            <td>1,200</td>
                            <td>Usage (per day)</td>
                            <td>350 - 600 users</td>
                        </tr>
                        <tr>
                            <td>• CD-ROM Databases (Current & Archival)</td>
                            <td>16</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>E.Content (Mobile Apps Remote-Knimbus, NDLI & e-Sarvajanika)</td>
                            <td>3</td>
                            <td></td>
                            <td></td>
                        </tr>

                    </tbody>
                </table>
                <br />
                {eBooks ? (<ListView table="implinkqty" data={eBooks} columns={columns} title="Electronic Books" pageSize="10" />) : ("No data found")}
                {eJournals ? (<ListView table="implinkqty" data={eJournals} columns={columns} title="Electronic Journals" pageSize="10" />) : ("No data found")}
                {aps ? (<ListView table="implink" data={aps} columns={linkColumns} title="Anti-Plagiarism Software" pageSize="10" />) : ("No data found")}
                {rama ? (<ListView table="implink" data={rama} columns={linkColumns} title="Remote Access & Mobile App" pageSize="10" />) : ("No data found")}
                {imln ? (<ListView table="implink" data={imln} columns={linkColumns} title="Institutional Membership to Library Networks" pageSize="10" />) : ("No data found")}
            </div>
        </>
    )
}

export default Collections;