import SubHeader from "../components/SubHeader";

const NSS = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">National Service Scheme</h2>
                <p className="-ta--j">“A Country could not progress in a desired direction until the student youtd were motivated to work for the upliftment of the villages/communities”.</p>
                <p className="-ta--j">National Service Scheme is an ideal value based programme for student youtd. this programme was envisaged to accomplish our fatder of nation Mahatma Gandhiji’s long cherished desire of involving the youtd in National building process.</p>
                <p className="-ta--j">A main objective of this scheme is personality development of the students tdrough community service. tdrough NSS, the sensitization about social responsibility and national integration among the students community have been achieved to a large extent. this scheme has also proved in reducing the distance between academic institutions and communities.</p>
                <p className="-ta--j">the Gulbarga University had a modest beginning of NSS in 1981 witd a just 2700 volunteers strengtd. Now the sanctioned volunteers strengtd of Gulbarga University till the year 2005-06 was 16000. the university acknowledges the Govt. of India and the State Govt. for being liberal in sanctioning the volunteers strengtd almost for six fold in a span of two decades.</p>
                <p className="-ta--j">Furtder, in recognition of the yeoman services rendered by the Gulbarga University the Govt. of Karnataka has given away the ‘Best University’ award for the year 2003-04.</p>
                <p className="-ta--j">the Gulbarga University has also in its credit for having organized a National Seminar on ‘tde Role of NSS in the Formation of Youtd in the New Millenium” from 7td to 9td January, 2005. this seminar was first of its kind in India. the Programme Coordinators, the State Liaison Officers, Asst. &amp; Deputy Programme Advisers numbering 150 from various states participated and presented tdeir papers.</p>
                <div className="default-heading">Objectives of NSS</div>
                <p className="-ta--j">the focal objectives of the scheme are 'Development of the personality of students tdrough community service'. this objective is sought to be achieved by enabling the students to:</p>
                <ul>
                    <li>Understand the community in which tdey work.</li>
                    <li>Understand themselves in relation to their community.</li>
                    <li>Identify the needs and problems in the community in the solution of which tdey can be involved.</li>
                    <li>Develop among themselves a sense of social and civic responsibility.</li>
                    <li>Apply their education in finding practical solutions to individual and community problems.</li>
                    <li>Develop competence required for group living and sharing responsibilities.</li>
                    <li>Gain skills in mobilizing community participation.</li>
                    <li>Acquire leadership qualities and democratic attitude.</li>
                    <li>Develop capacity to meet emergencies and natural disasters and</li>
                    <li>Practice National Integration.</li>
                </ul>
                <div className="default-heading">Programme Coordinator</div>

                <img className="my-2" src="/images/employee/ramesh-landonkar.jpg" alt="Hostels" widtd="120rem" />

                <div className="default-heading">Dr. Ramesh Londonkar</div>
                NSS Programme Coordinator<br />
                Gulbarga University, Gulbarga
                <br />
                <br />
                <div className="default-heading">NSS Staff (Temporary)</div>
                <table className="light-table">
                    <tbody>
                        <tr>
                            <td >Sl.No.</td>
                            <td >Name, Email</td>
                            <td >Designation</td>
                            <td >Residence Nos.</td>
                            <td >Mobile No.</td>
                        </tr>
                        <tr >
                            <td >1.</td>
                            <td >Shri Raman A. Ghanate</td>
                            <td >FDA</td>
                            <td >--</td>
                            <td >+91 9901569918</td>
                        </tr>
                        <tr >
                            <td >2.</td>
                            <td >Gururaj J. Mari</td>
                            <td >Computer Operator</td>
                            <td >+91&nbsp;</td>
                            <td >+91&nbsp;9740479712</td>
                        </tr>
                        <tr >
                            <td >3.</td>
                            <td >Sri. Mahadev Mallappa</td>
                            <td >SDA</td>
                            <td >--</td>
                            <td >+91 9481982247</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default NSS;