import SubBanner from "../../components/SubHeader";

const AwardsCredentials = () => {
    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className="container my-4">
                <h3 className="heading">AWARDS AND CREDENTIALS</h3>
                <p className="-ta--j">Gulbarga University has received several awards for its excellence in Library services and adoption of ICT in the university library system.</p>
                <ul className="-ta--j">
                    <li>Nominated for Informatics Innovation Award for Open Air Green Library with Wi-Fi, e-books and journal collection accessible through Computers, Tabs and Kindles during Library Technology Conclave (LTC-2021).</li>
                    <li>Best KOHA Implementation Award @ Tata Consultancy Service, Pune during 2017</li>
                    <li>e-INDIA "Best ICT Enabled University of the Year" Jury Award for Excellence in ICT and Innovative endeavours made in integrating the Technology in Digital Learning - Education. 7th e-India Summit, 15th December 2011.</li>
                    <li>Dr. Padhya Best University Library Award instituted by the Indian Library Association (ILA), New Delhi, Dec. 2007</li>
                </ul>
                <p className="-ta--j">Capacity building and skills up-gradation to develop competent and well trained Library staff to take the challenges of the technological developments and they have received fellowships, awards, foreign academic assignments and participated in various national and international Conferences, Workshops and Training programs in India and abroad.</p>
                <div className="default-heading">International Credentials</div>
                <ul className="-ta--j">
                    <li>University of East London and London School of Economics and published article @ Sheffield University, UK during April 2013 (Dr. Suresh Jange)</li>
                    <li>International Conference on Knowledge Management & Resource Sharing organized by Waljat College of Applied Sciences from 27th - 28th February 2012, Muscat, Oman and then visited RAK Medical and Health Sciences University, Ras Al Khaimah, United Arab Emeritus to setup Digital Library and facilitate Remote Access facility, March 2012 (Dr. Suresh Jange, Deputy Librarian).</li>
                    <li>Member of delegation during December 2011 for International collaboration and signing IMOU with Colorado State University, Fort Collins, USA to establish International Institute for Food Energy and Water Security at Gulbarga University (Dr R.B. Gaddagimath, University Librarian).</li>
                    <li>Victoria University, Wellington, New Zealand Advanced Learning Training under IFLA-ALP Fellowship, 2007. (Dr. Suresh Jange, Assistant Librarian)</li>
                    <li>Nanyang Technological University, Singapore Asia-Pacific Conference on Library & Information Education and Practice (ALIEP) and Visited National Public Library, National Library Kaulalampur, Malaysia and Academic Libraries in Sri Lanka, 2006. (Dr. Amrut Sherikar, Deputy Librarian and Dr. Suresh Jange, Assistant Librarian)</li>
                    <li>WMSCI - 2007 4th International Symposium on RMCI, Florida, USA, and visited Univ. of Florida, (Sri. Parusharam Kattimani, Assistant Librarian).</li>
                    <li>ASIST Conference, Texas, USA, and visited Public Library, Chicago, University of Dallas, Texas, University of Medial Sciences, Arkansas, New York Public Library and Universities in New York, and one week training at Syracuse University, 2006. (Dr. Amrut Sherikar, Deputy Librarian).</li>
                    <li>S.R. University, Ezemer, Turkey, and visits to National University, Bulgeria, and National Library, Thailand, Nanyang National University, Singapore, 2005 (Dr. G.Z.Shinde, Deputy Librarian).</li>
                    <li>University of Tokyo, Cobe, Japan and visit to University of Malaysia, Dec. 2005 (Dr. G.Z.Shinde, Deputy Librarian).</li>
                    <li>Karolinska University, Stockholm, Sweden 10th International Conference of the International Society for Scientometrics and Informatics (ISSI), 2005. Dr. Suresh Jange, Assistant Librarian).</li>
                    <li>SAARC Countries Training Program for Librarians, 2001. (Dr. S.B.Patil, Deputy Librarian).</li>
                    <li>Tribhuvan University Library and British Library, Nepal, 2001 (Dr. Amrut Sherikar, Deputy Librarian).</li>
                    <li>University of Teesside, Middlesbrough, UK, 2001. (Dr. M.T.Kattimani, Deputy Librarian).</li>
                </ul>

                <div className="default-heading">National Credentials</div>
                <ul className="-ta--j">
                    <li>Government of Karnataka nominated as Chairman of State Level Book Selection Committee of the Department of Public Libraries, Ministry of Public Libraries, Government of Karnataka (2023-2025) (Dr. Suresh Jange)</li>
                    <li>Dr. Suresh Jange - Received ILA - KAULA Best Librarian award - 2020 during 66th ILA International conference on Sept 27-28, 2021.</li>
                    <li>Dr. Suresh Jange, Elected Vice President (South) - Indian Library Association, New Delhi (2022-2025)</li>
                    <li>Dr. Praveenkumar K, Editorial Member of Editorial Board Member for Indian Streams Research Journals, Golden Research Thoughts, E-Library Science and Review of Research and Co-editor of Chalukya Journal of Social Science.</li>
                    <li>Dr. Mamta Mestri received Best Librarian Award during 2007 from Karnataka State SC/ST Library Professionals Association held at University of Mysore.</li>
                    <li>Dr Suresh Jange, received Best Academic Librarian Award 2010-2011 - Government of Karnataka</li>
                    <li>Dr. R.B.Gaddagimath, University Librarian received Best Academic Librarian Award 2009-10 - Government of Karnataka</li>
                    <li>Dr. R.B.Gaddagimath, University Librarian received Vijay Shree Award for outstanding Services, Achievements and Contributions instituted by India International Friendship Society, New Delhi, 2005.</li>
                    <li>Dr. Amruth Sherikar, Deputy Librarian received Sir Ratan Tata Fellowship for paper presentation at American Society for Information Science & Technology, Austin, Texas, USA; Nov. 2006.</li>
                    <li>Dr. Suresh Jange received IFLA-ALP (International Federation of Library Associations) Fellowship Award for Advanced Learner Training in Victoria University, Wellington, New Zealand, 2007.</li>
                    <li>Shri Khemanna Aldi, "Ramakka Padmakka outstanding Young Professional in University Library" award instituted by Karnataka State Library Association, Bangalore (2000-01).</li>
                    <li>Shri G. Ramaiah, Library Assistant received Karnataka Rajya Dr. B.R. Ambedkar Ratna Prashasti (2008)</li>
                    <li>Dr. R.B.Gaddagimath, University Librarian was invited to be the Director of 5th International Conference CALIBER - 2007, Punjab University, Chandigarh and also has received many awards, honors and recognition for contributions in the Library and Information Science field.</li>
                </ul>
            </div>
        </>
    )
}

export default AwardsCredentials;