import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gs from '../services/gs';
import { BUCKET_URL } from '../services/helper';

const NewsSectionLib = () => {

    const [news, setNews] = useState();
    const [implink, setImplink] = useState();

    useEffect(() => {
        gs.getByType('notification', '52').then(res => {
            setNews(res);
        });
        gs.getByType('implink', '51').then(res => {
            setImplink(res);
        });
    }, []);

    return (
        <div className="container d-flex news-section">
            <div className="col news" data-aos="fade-right">
                <h3 className="heading">News & Notifications</h3>

                <marquee className="mrq" direction="up" scrollamount="2" scrolldelay="1" onMouseOver="this.stop()" onMouseOut="this.start()">
                    <ul className='news-list'>
                        {news && news.map((eachRow) => {
                            const current = new Date(eachRow.udate);
                            return (
                                <li key={eachRow.id} className="list-item">
                                    <div className="firstpart">
                                        <h6>{current.getDate()}</h6>
                                        <div>{current.toLocaleString('en-US', { month: 'short' })}-{current.getFullYear()}</div>
                                    </div>
                                    <div className='secondpart'>
                                        <a href={BUCKET_URL + '' + eachRow.url} target='_blank' rel="noreferrer" >{eachRow.title}</a>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </marquee>
                <div className='d-flex justify-content-end'>
                    <Link className="read-more-btn" data-aos="fade-right" to="news-notification">
                        Read More
                    </Link>
                </div>
            </div>

            <div className="col news" data-aos="fade-left">
                <h3 className="heading">Useful Links</h3>
                <ul className='news-list ms-2'>
                    {implink && implink.map((eachRow) => {
                        return (
                            <li className='list-item' key={eachRow.id}>
                                <a href={eachRow.url} target="_blank" rel="noreferrer" >{eachRow.title}</a>
                            </li>)
                    })}
                </ul>
                <div className='d-flex justify-content-end'>
                    <Link className="read-more-btn" data-aos="fade-right" to="/implink">
                        More Links
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NewsSectionLib;