import React, { useEffect, useState } from 'react';
import ListView from '../../components/ListView';
import SubBanner from "../../components/SubHeader";
import gs from '../../services/gs';

const Training = () => {
    const [columns, setColumns] = useState();
    const [cwpt, setCwpt] = useState();

    useEffect(() => {
        gs.getListColumns('libcwtp').then(res => {
            setColumns(res);
        });

        gs.getAll('libcwtp').then(res => {
            setCwpt(res);
        });
    }, []);

    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">TRAINING AND DEVELOPMENT</h3>
                <div className="heading default-heading">A. INFORMATION LITERACY PROGRAMS</div>
                <p className="-ta--j">The Library undertakes 21 days of information literacy programme to PG students every year.  It includes library policy, basic computer literacy, digital literacy, electronic literacy, knowledge about library consortia, open sources, institutional repository, etc.  Apart from the same, the library visit and hands-on training is provided to users regularly. Even one-to-one guidance is also made by VLRC staff to access and use e-resources.</p>
                <img src="/images/library/ILP.jpg" alt="Vice-Chancellor" width="825rem" />
                <br />
                <br />
                <p className="-ta--j">To promote use and acquaintance of e-resources, learning materials, e-resources, CD/DVD Databases, research communication, presentation skills, information exchange and online access to Internet and web resources, the Library organizes User focused training Programs on ICT Skills, Research Communication and Information Management. Topics on research planning, ICTs and E-resources, Internet, E-communication, Discussion forums, Data Analysis packages, citation patterns, thesis/dissertation writing, technical paper writing, Inflibnet e-resources, search skills, developing materials for scientific presentations, news reporting and editing for press and media are covered for different target groups.</p>
                <div className="heading default-heading">B.	INTERNSHIP TRAINING TO M.L.I.SC STUDENTS (1 MONTH)</div>
                <p className="-ta--j">The Library provides Internship program for 1 month to the final year students of M.L.I.Sc every academic year. Systematic schedule is prepared to ensure the students work in all the library activities and services and they are given hands on experience of using Digital Library, RFID enabled transactions, KOHA software, Institutional repository, Stack section and Innovative practices. On completion of Internship, Certificates are issued.</p>
                <div className="heading default-heading"> C. CONFERENCES, WORKSHOPS AND TRAINING PROGRAMS ORGANIZED</div>
                {cwpt ? (<ListView table="implink" data={cwpt} columns={columns} title="" pageSize="10" />) : ("No data found")}
            </div>
        </>
    )
}

export default Training;