import SubHeader from "../components/SubHeader";
import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import gs from "../services/gs";

const Regulations = () => {
    const [posts, setPosts] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getListColumns('notification').then(res => {
            setColumns(res);
        });
        gs.getByType('notification','14').then(res => {
            setPosts(res);
        });
    }, []);

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Regulations</h2>
                <ListView table="notification" data={posts} columns={columns}  title="" pageSize="30" download="true"/>
            </div>
        </div>
    );
}

export default Regulations;