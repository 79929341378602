import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../../services/auth.service";
import { toast } from "react-toastify";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const ChangePassword = () => {

  const form = useRef();
  const checkBtn = useRef();

  const username = JSON.parse(localStorage.getItem('user')).userName;
  const [password, setPassword] = useState("");
  const [passwordVerify, setPasswordVerify] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onChangePasswordVerify = (e) => {
    setPasswordVerify(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if (password !== passwordVerify) {
        setMessage("Password mismatch");
        setLoading(false);
      } else {
        
        AuthService.changePassword(username, password).then(
          () => {
            toast.success("Password updated");
            setLoading(false);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setLoading(false);
            setMessage(resMessage);
            toast.error(resMessage);
          }
        );
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="col-md-12">
      <div className="login-card card-container">
        <Form onSubmit={handleSubmit} ref={form}>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Re-enter New Password</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={passwordVerify}
              onChange={onChangePasswordVerify}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block mt-3" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Update</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;