
import SubHeader from "../components/SubHeader";

const Sports = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Sports & Gym</h2>
                <img className="my-2" src="/images/departments/sports.jpg" alt="Vice-Chancellor" width="620rem" />
                <p className="-ta--j my-2">The University has established excellent infrastructural facilities in sports. The Physical Education Department has well qualified staff members, coaches and instructors to guide the motivated sports persons. Excellent indoor stadium having four courts and a well-equipped gymnasium with a capacity of 1750 audience in the gallery has been established. The University also has an outdoor stadium with 400 metre track for various events. The University has developed a Football ground, two Hockey grounds, two Volleyball courts, Kho-Kho, Kabaddi, Basketball courts, Badminton tennis courts, Ballbadminton Tennis court, two Cricket grounds, four table tennis courts. Besides, modern gymnasium facilities are also provided for the benefit of the students and the faculty. The University hosts various inter University, state, regional, national and international level sports and games.</p>
            </div>
        </div>
    );
}

export default Sports;