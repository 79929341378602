
import SubHeader from "../components/SubHeader";

const StaffQuarters = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Staff Quarters</h2>
            </div>
        </div>
    );
}

export default StaffQuarters;