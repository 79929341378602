
import MenuItems from './MenuItems';

const Navbar = ({ data }) => {
  return (
    <nav>
      <ul className="menus">
        {data && data.map((eachRow, index) => {
          const depthLevel = 0;
          return <MenuItems items={eachRow} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
};

export default Navbar;