import { Link } from "react-router-dom";

const AdminSideList = ({ data }) => {
    return (
        <div className="flex-shrink-0 p-3 bg-white" >
            <Link to="/admin" className="d-flex align-items-center pb-3 mb-3 link-dark text-decoration-none border-bottom">
                <span className="fs-5 fw-semibold">Tables</span>
            </Link>
            <ul className="list-unstyled ps-0">
                {data && data.map((eachRow, index) => {
                    return (
                        <li key={index} className="mb-1">
                            <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0 collapsed" data-bs-toggle="collapse" data-bs-target={`#${eachRow.name}`} aria-expanded="false">
                                {eachRow.label}
                            </button>
                            <div className="collapse" id={eachRow.name}>
                                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ps-3 small">
                                    <li><Link to={`form/${eachRow.name}`} className="link-dark d-inline-flex text-decoration-none rounded">New</Link></li>
                                    <li><Link to={`list/${eachRow.name}`} className="link-dark d-inline-flex text-decoration-none rounded">All</Link></li>
                                </ul>
                            </div>
                        </li>
                    )
                })}
                <li className="border-top my-3"></li>
                <li><Link to="upload">Upload</Link></li>
                <li><Link to="text-editor">Text Editor</Link></li>
                <li className="border-top my-3"></li>
                <li className="mb-1">
                    <button className="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#account-collapse" aria-expanded="true">
                        Account
                    </button>
                    <div className="collapse show" id="account-collapse" >
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ps-3 small">
                            <li><Link to="change-password">Change Password</Link></li>
                            <li><a href="#" className="link-dark d-inline-flex text-decoration-none rounded">Profile</a></li>
                            <li><a href="#" className="link-dark d-inline-flex text-decoration-none rounded">Settings</a></li>
                            <li><a href="#" className="link-dark d-inline-flex text-decoration-none rounded">Sign out</a></li>
                        </ul>
                    </div>
                </li>

            </ul>
        </div>
    )
}

export default AdminSideList;