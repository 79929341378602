import Card from "./Card";

const highlights = [
    {
        image: "./images/admissions.jpg",
        title: `Admissions`,
        url: "admissions",
    },
    {
        image: "/images/nep.jpg",
        title: `National Education Policy 2020`,
        url: "nep"
    },
    {
        image: "./images/uumcs.png",
        title: "UUCMS",
        subtitle: "Dept of Higher Education, Govt of Karnataka",
        url: "uucms",
    },
    {
        image: "/images/sr.jpg",
        title: "Regulations",
        url: "regulations",
    },
    {
        image: "/images/colleges.jpg",
        title: "Affiliated Colleges",
        url: "affiliated-colleges",
    },
    {
        image: "/images/sw.jpg",
        title: "Student Welfare",
        url: "page/swo",
    },
    {
        image: "/images/nss.jpg",
        title: "National Service Scheme",
        subtitle: "National Service Scheme",
        url: "page/nss",
    },
    {
        image: "/images/notifications.png",
        title: "Notifications",
        subtitle: "Administration, Academic and Examination",
        url: "notifications",
    }
];

const Highlights = () => {
    return (
        <div className="d-flex highlights">
            {highlights.map((item, index) => (
                <Card key={index} data={item} />
            ))}
        </div>
    );
};

export default Highlights;