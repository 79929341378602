import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SubHeader from "../components/SubHeader";
import { instAxios } from '../services/helper';

const Faculty = () => {
    const { t } = useTranslation();
    const { facultyId } = useParams();

    const [department, setDepartment] = useState();
    const [faculty, setFaculty] = useState();

    useEffect(() => {
        instAxios.get('/department/faculty?faculty=' + facultyId).then(res => {
            setDepartment(res.data);
        });
        instAxios.get('/faculty/url?url=faculty/' + facultyId).then(res => {
            setFaculty(res.data);
        });
    }, [facultyId])

    // const dept = departments.filter(item => item.faculty === facultyId);
    // const cFaculty = faculties.find(item => item.url === 'faculty/' + facultyId);
    return (
        <div>
            <SubHeader />
            <div className="container my-4 faculty">
                {faculty && <h2 className="heading">{t(faculty.name)}</h2>}
                <ul style={{ paddingLeft: '0px' }}>
                    {department && department.map((each, index) => {
                        return (
                            <li key={index}><Link to={each.url}>{t(each.name)}</Link></li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Faculty;