import SubHeader from "../components/SubHeader";

const Alumni = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Alumni</h2>
            </div>
        </div>
    );
}

export default Alumni;