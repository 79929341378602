import React, { useState, useEffect } from 'react';
import gs from '../services/gs';

const IQAC = () => {

    const [tab, setTab] = useState('home');
    const [home, setHome] = useState();
    const [aqar, setAqar] = useState();
    const [minutes, setMinutes] = useState();
    const [naac, setNaac] = useState();
    const [aishe, setAishe] = useState();
    const [nirf, setNirf] = useState();
    const [survey, setSurvey] = useState();
    const [members, setMembers] = useState();
    const [reports, setReports] = useState();
    const [cycle, setCycle] = useState();
    const [criteria, setCriteria] = useState();
    const [contact, setContact] = useState();

    useEffect(() => {
        gs.getContent('138').then(res => {
            setHome(res.content);
        });
        gs.getContent('139').then(res => {
            setAqar(res.content);
        });
        gs.getContent('140').then(res => {
            setMinutes(res.content);
        });
        gs.getContent('141').then(res => {
            setNaac(res.content);
        });
        gs.getContent('142').then(res => {
            setAishe(res.content);
        });
        gs.getContent('143').then(res => {
            setNirf(res.content);
        });
        gs.getContent('144').then(res => {
            setSurvey(res.content);
        });
        gs.getContent('145').then(res => {
            setMembers(res.content);
        });
        gs.getContent('146').then(res => {
            setReports(res.content);
        });
        gs.getContent('147').then(res => {
            setCycle(res.content);
        });
        gs.getContent('297').then(res => {
            console.log(res);
            setCriteria(res.content);
        });
        gs.getContent('148').then(res => {
            setContact(res.content);
        });
    }, []);

    const onClickTab = (tab) => {
        setTab(tab);
    }

    const tabs = [{
        label: "Home",
        name: "home"
    },
    {
        label: "AQAR",
        name: "aqar"
    },
    {
        label: "Minutes",
        name: "minutes"
    },
    {
        label: "NAAC",
        name: "naac"
    },
    {
        label: "AISHE",
        name: "aishe"
    },
    {
        label: "NIRF",
        name: "nirf"
    },
    {
        label: "Student Survey",
        name: "survey"
    },
    {
        label: "Members",
        name: "members"
    },
    {
        label: "Circulars",
        name: "reports"
    },
    {
        label: "4th Cycle NAAC",
        name: "cycle"
    },
    {
        label: "Criteria",
        name: "criteria"
    },
    {
        label: "Contact",
        name: "contact"
    }];

    return (
        <div>
            <div className="container my-4">
                <h2 className="heading">IQAC</h2>
                <ul className="nav nav-tabs">
                    {tabs.map((eachRow) => {
                        return (
                            <li key={eachRow.name} className="nav-item">
                                <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                            </li>
                        )
                    })}
                </ul>

                <div className="tab-content">
                    {tab === 'home' ? (
                        <div id="home">
                            <div dangerouslySetInnerHTML={{ __html: home }} />
                        </div>
                    ) : null}
                    {tab === 'aqar' ? (
                        <div id="aqar">
                            <div dangerouslySetInnerHTML={{ __html: aqar }} />
                        </div>
                    ) : null}
                    {tab === "minutes" ? (
                        <div id="minutes">
                            <div dangerouslySetInnerHTML={{ __html: minutes }} />
                        </div>
                    ) : null}
                    {tab === "naac" ? (
                        <div id="naac">
                            <div dangerouslySetInnerHTML={{ __html: naac }} />
                        </div>
                    ) : null}
                    {tab === "aishe" ? (
                        <div id="aishe">
                            <div dangerouslySetInnerHTML={{ __html: aishe }} />
                        </div>
                    ) : null}
                    {tab === "nirf" ? (
                        <div id="nirf">
                            <div dangerouslySetInnerHTML={{ __html: nirf }} />
                        </div>
                    ) : null}
                    {tab === "survey" ? (
                        <div id="survey">
                            <div dangerouslySetInnerHTML={{ __html: survey }} />
                        </div>
                    ) : null}
                    {tab === "members" ? (
                        <div id="members">
                            <div dangerouslySetInnerHTML={{ __html: members }} />
                        </div>
                    ) : null}
                    {tab === "reports" ? (
                        <div id="reports">
                            <div dangerouslySetInnerHTML={{ __html: reports }} />
                        </div>
                    ) : null}
                    {tab === "cycle" ? (
                        <div id="cycle">
                            <div dangerouslySetInnerHTML={{ __html: cycle }} />
                        </div>
                    ) : null}
                    {tab === "criteria" ? (
                        <div id="criteria">
                            <div dangerouslySetInnerHTML={{ __html: criteria }} />
                        </div>
                    ) : null}
                    {tab === "contact" ? (
                        <div id="contact">
                            <div dangerouslySetInnerHTML={{ __html: contact }} />
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default IQAC;