import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import _ from "lodash";
import gs from '../services/gs';

const EmployeeDirectory = () => {
    const [tab, setTab] = useState('teaching');
    const [teaching, setTeaching] = useState();
    const [nonteaching, setNonteaching] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {

        gs.getListColumns('employee').then(res => {
            setColumns(res);
        });

        gs.getAll('employee').then(res => {
            setTeaching(_.filter(res, ['type.value', '5']));
            setNonteaching(_.filter(res, ['type.value', '6']));
        });
    }, []);

    const onClickTab = (tab) => {
        setTab(tab);
    }

    const tabs = [{
        label: "Teaching",
        name: "teaching"
    },
    {
        label: "Non-teaching",
        name: "nonteaching"
    }];

    return (

        <div className="container my-4">
            <h3 className="heading">Faculty Directory</h3>

            <>
                <ul className="nav nav-tabs">
                    {tabs.map((eachRow) => {
                        return (
                            <li key={eachRow.name} className="nav-item">
                                <button className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</button>
                            </li>
                        )
                    })}
                </ul>

                <div className="tab-content">
                    {tab === 'teaching' ? (
                        <div id="teaching">
                            {teaching ? (<ListView table="faculty" data={teaching} columns={columns} title="Faculty" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : (
                        <div id="nonteaching">
                            {nonteaching ? (<ListView table="faculty" data={nonteaching} columns={columns} title="Staff" pageSize="20" />) : ("No data found")}
                        </div>
                    )}
                </div>
            </>
        </div>

    )
}

export default EmployeeDirectory;