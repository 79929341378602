import { useState } from "react";
import { ApiServiceCreate, ApiServiceUpdate } from "../services/api-service";
import { toast } from "react-toastify";
import Select from "./Select";
import _ from "lodash";

const FormView = ({ title, table, id, columns, action, formClosed }) => {

    const [data, setData] = useState(columns);

    const handleChange = (event, property) => {

        const index = data.findIndex(item => item.name === property.name);
        let temp = [...data]; // important to create a copy, otherwise you'll modify state outside of setState call

        if (property.type === "Options" || property.type === "Reference") {
            temp[index].data = {
                label: event.target[event.target.selectedIndex].text, value: event.target.value
            };
        }
        else if (property.type === "Boolean") {
            temp[index].data = !temp[index].data;
        }
        else {
            temp[index].data = event.target.value;
        }
        setData(temp);
    }

    const onSave = () => {
        var mapped = data.map(item => ({ [item.name]: item.data }));
        const objId = { id: id } // resp will contain newly created Id
        mapped.push(objId); // required as we are not showing in the column
        var newObj = Object.assign({}, ...mapped);

        if (action === "save") {
            ApiServiceCreate(newObj, table).then((resp) => {
                toast.success("Record created");
                _.set(newObj, 'id', resp);
                formClosed({ action: 'save', payload: newObj });
            }).catch((error) => {
                console.log(error);
                toast.error(error.message);
            });
        } else if (action === "update") {
            ApiServiceUpdate(newObj, table).then((resp) => {
                toast.success("Record updated");
                formClosed({ action: 'update', payload: newObj });
            }).catch((error) => {
                console.log(error);
                toast.error(error.message);
            });
        }
    }
    const renderSwitch = (eachRow) => {
        switch (eachRow.type) {
            case 'Reference':
            case 'Options':
                return (
                    <Select eachRow={eachRow} handleChange={(e, eachRow) => handleChange(e, eachRow)} />
                )
            case 'Boolean':
                return <input type="checkbox" className="checkbox" checked={eachRow.data} value={eachRow.data} onChange={(e) => handleChange(e, eachRow)} />
            case 'Integer':
                return <input type="number" className="form-control" value={eachRow.data} onChange={(e) => handleChange(e, eachRow)} />
            default:
                return <input type="text" className="form-control" value={eachRow.data} onChange={(e) => handleChange(e, eachRow)} />
        }
    }

    return (
        <div className="modal" tabIndex="-1" style={{ display: 'block' }}>
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => formClosed({ action: 'close', payload: null })}></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            {columns.map((eachRow, index) => {
                                return (
                                    <div key={index} className="form-group">
                                        <label className="col-xs-12 col-md-3 col-lg-4 control-label" >{eachRow.label}</label>
                                        <div className="col-xs-12 col-md-3 col-lg-4 form-field input_controls">
                                            {renderSwitch(eachRow)}
                                        </div>
                                    </div>
                                )
                            })}
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => formClosed({ action: 'close', payload: null })}>Close</button>
                        <button type="button" className="btn btn-primary" onClick={() => onSave()}>{action}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default FormView;