import React, { useEffect, useState } from 'react';
import { instAxios } from '../services/helper';

const Announcements = ({
    delay = 1,
    duration = 20,
    gradient = true,
    gradientColor = [128, 0, 0],
    gradientWidth = 200, }) => {

    const rgbaGradientColor = `rgba(${gradientColor[0]}, ${gradientColor[1]}, ${gradientColor[2]}`;
    const [data, setData] = useState();

    useEffect(() => {
        const current = new Date();
        const udate = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;

        instAxios.get('announcement/announce?udate=' + udate).then(res => {
            setData(res.data);
        });
    }, []);

    return (

        <div style={{ "--pause-on-hover": "paused", "--pause-on-click": "paused" }} className="marquee-container">
            {gradient && (
                <div className="overlay"
                    style={{
                        "--gradient-color": `${rgbaGradientColor}, 1), ${rgbaGradientColor}, 0)`,
                        "--gradient-width": typeof gradientWidth === "number" ? `${gradientWidth}px` : gradientWidth,
                    }} />
            )}
            <div className="marquee"></div>
            <div className="marquee"
                style={{
                    "--play": "running",
                    "--direction": "normal",
                    "--duration": `${duration}s`,
                    "--delay": `${delay}s`,
                }}
                aria-hidden="true">
                {data && data.map((eachRow) => {
                    return (
                        <a key={eachRow.id} className='strip' href={eachRow.url} target="_blank" rel="noreferrer">{eachRow.title}</a>
                    )
                })}
            </div>
        </div>
    );
};

export default Announcements;