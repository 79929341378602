import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SubHeader from "../components/SubHeader";
import { BUCKET_URL, instAxios } from '../services/helper';

const NEPSyllabus = () => {
    const { t } = useTranslation();
    const { nepId } = useParams();
    const [faculty, setFaculty] = useState();
    const [subject, setSubject] = useState();
    const [syllabus, setSyllabus] = useState();

    useEffect(() => {
        instAxios.get('/faculty/url?url=' + nepId).then(res => {
            setFaculty(res.data);
        });
    }, [nepId]);

    useEffect(() => {
        if (faculty !== undefined) {
            instAxios.get('/subject/faculty?faculty=' + faculty.id).then(res => {
                setSubject(res.data);
            });
        }
    }, [faculty]);

    const onClickHandle = (subjectId) => {
        instAxios.get('/syllabus/cs?course=40&subject=' + subjectId).then(res => {
            setSyllabus(res.data);
        });
    }

    const downloadFile = (url) => {
        window.open(BUCKET_URL + url, '_blank');
    }

    return (
        <>
            <SubHeader />
            <div className='container my-3'>
                <h3 className='heading'>{faculty && faculty.label}</h3>
                <div className="accordion" id="accordionExample">
                    {subject && subject.map((eachRow, index) => {
                        return (
                            <div key={index} className="accordion-item">
                                <h2 className="accordion-header" id={`heading_${index}`}>
                                    <button className="accordion-button" type="button" onClick={() => onClickHandle(eachRow.id)} data-bs-toggle="collapse" data-bs-target={`#collapse_${index}`} aria-expanded="false" aria-controls={`collapse_${index}`}>
                                        {eachRow.label}
                                    </button>
                                </h2>
                                <div id={`collapse_${index}`} className="accordion-collapse collapse" aria-labelledby={`heading_${index}`} data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div>
                                            {syllabus && syllabus.map((eachSy) => {
                                                return (
                                                    <div key={eachSy.id} className='row bg-light mb-2 align-middle'>
                                                        <div className='col-4 align-self-center'>{eachSy.label}</div>
                                                        <div className='col-2'>
                                                            <button className='btn btn-outline-primary btn-sm' onClick={() => downloadFile(eachSy.url)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default NEPSyllabus;