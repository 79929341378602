import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const MegaMenu = ({ data }) => {
    const { t } = useTranslation();
    return (
        <ul className="tabs">
            {data && data.map((eachRow) => {
                return (
                    <li key={eachRow.id}>
                        {eachRow.url.startsWith('http') ? (<a href={eachRow.url} target="_blank" rel="noreferrer">{t(eachRow.name)}</a>) : (<Link to={eachRow.url}>{t(eachRow.name)}</Link>)}
                    </li>
                )
            })}
        </ul>
    )
}

export default MegaMenu;