import { BUCKET_URL } from "../services/helper";

const EmpList = ({ data }) => {

    const downloadFile = (url) => {
        window.open(BUCKET_URL + url, '_blank');
    }

    return (
        <>
            <div className='row py-1 bg-light border-top border-bottom'>
                <div className='col-2 fw-bold'>
                    Photo
                </div>
                <div className='col-4 fw-bold'>
                    Name
                </div>
                <div className='col-2 fw-bold'>
                    Qualification
                </div>
                <div className='col-4 fw-bold'>
                    Specialization
                </div>
            </div>
            {
                data && data.map((eachEmp, index) => {
                    return (
                        <div key={index} className='row border-bottom py-2'>

                            <div className='col-2'>
                                <img className="img-fluid" src={BUCKET_URL + eachEmp.photoUrl} alt={eachEmp.name} width="120rem" />
                            </div>
                            <div className='col-4'>
                                <div className='default-heading download-link' title="Click to download profile" onClick={() => downloadFile(eachEmp.profileUrl)}>{eachEmp.name}</div>
                                {eachEmp.designation.label}<br />
                                {eachEmp.contactNumber}<br />
                                {eachEmp.emailId}
                            </div>
                            <div className='col-2'>
                                {eachEmp.qualification}
                            </div>
                            <div className='col-4'>
                                {eachEmp.specialization}
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default EmpList;