import React, { useEffect, useState } from 'react';

import CarouselSmall from "./CarouselSmall";
import gs from '../services/gs';

const GallerySection = () => {

    const [cdata, setCdata] = useState();

    useEffect(() => {
        gs.getByType('carousel', '47').then(res => {
            setCdata(res);
        });
    }, []);

    const captionStyle = {
        fontSize: "2em",
        fontWeight: "bold",
    };
    const slideNumberStyle = {
        fontSize: "20px",
        fontWeight: "bold",
    };

    return (
        <div className="container flex-container">
            <div className="flex-child-container" data-aos="fade-up">
                <h3 className="heading">Events Gallery</h3>
                <CarouselSmall
                    data={cdata}
                    time={3000}
                    width="100%"
                    height="316px"
                    captionStyle={captionStyle}
                    radius="1px"
                    slideNumber={false}
                    slideNumberStyle={slideNumberStyle}
                    captionPosition="bottom"
                    automatic={true}
                    dots={true}
                    pauseIconColor="white"
                    pauseIconSize="40px"
                    slideBackgroundColor="darkgrey"
                    slideImageFit="cover"
                    thumbnails={false}
                    thumbnailWidth="100px"
                    showNavBtn={true}
                    style={{
                        textAlign: "center"
                    }}
                />
            </div>
            <div className="flex-child-container" data-aos="fade-up">
                <h3 className="heading">Videos</h3>
                <div id="images-div">
                    <div id="gallery" style={{ display: 'block', margin: '0 auto' }}>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/xbLfgcyvPm4" title="Gulbarga University Campus Tour" frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GallerySection;