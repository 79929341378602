import SubBanner from "../../components/SubHeader";

const LGP = () => {
    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">Library Governance and Policies</h3>
                <p className="-ta--j">Gulbarga University Library, a knowledge and learning resource centre for accessibility developed on modern lines on sprawling University Campus with Open Air Green Library, ideally situated and easily accessible by all the departments and students housing with a carpet area of 7,525 Sq. Meters with seating capacity of 1000 users. It is established to provide accessibility to printed as well as electronic resources (e-resources) to PG students, research scholars, faculty members, academicians and outside registered member users, who are interested to gain knowledge and information for their career and professional needs by providing variety of information sources such as Books, Periodicals, Reference sources and e-resources and information services based on the information requirements of its users. </p>
                <div className="default-heading">Mission</div>
                <p className="-ta--j"> The faculty members, research scholars, PG students and registered user members are partners in the communication and dissemination of knowledge and information needed for teaching, learning higher education and research.  Fulfilling the knowledge, learning, higher educational needs, research needs of the region are mission of Gulbarga University Library.  To achieve its mission, the library aims:</p>
                <ul className="-ta--j">
                    <li>To provide access to a variety of printed and electronic learning resources that have been accessed in accordance with the higher educational and research needs of the library member users.</li>
                    <li>To communicate and disseminate the users about all the knowledge and information sources that are available and accessible in the library and work with them in search of new resources for collection and development to support teaching, learning and research in Gulbarga University. </li>
                    <li>To organize, maintain, manage, develop and ensure optimum use of library facilities and services. </li>
                    <li>To support the library member users by increasing reading and studying through user education and information literacy programmes that are organized regularly. </li>
                    <li>To ensure best use of library resources through variety of library services based on knowledge and information needs of member users. </li>
                </ul>
                <p className="-ta--j">Library has a Library Advisory Committee (LAC) (Syndicate Sub-Committee) and it is a supreme body for the development of Library activities and services.</p>
                <div className="default-heading">Composition  of LAC</div>
                <ul>
                    <li>The Vice-Chancellor (Chairman)</li>
                    <li>Registrar</li>
                    <li>Syndicate Member (One)</li>
                    <li>Academic Council Members (One)</li>
                    <li>All Deans (Six Members)</li>
                    <li>Professor (One)</li>
                    <li>External Expert in Library Procurement</li>
                    <li>Finance Officer (Invitee)</li>
                    <li>University Librarian (Convener)</li>
                </ul>
                <p className="-ta--j">The Library has developed Library Manual that acts as a guideline for carrying out activities and services and maintains better transparency.</p>
            </div>
        </>
    )
}

export default LGP;