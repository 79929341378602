import SubHeader from "../components/SubHeader";
import React, { useEffect, useState } from 'react';
import _ from "lodash";
import TabAY from "../components/TabAY";
import { instAxios } from "../services/helper";

const FeesStructure = () => {
    const [tab, setTab] = useState('ug');
    const [columns, setColumns] = useState();

    const [ug4, setUg4] = useState();
    const [ug3, setUg3] = useState();
    const [ug2, setUg2] = useState();
    const [ug1, setUg1] = useState();

    const [pg4, setPg4] = useState();
    const [pg3, setPg3] = useState();
    const [pg2, setPg2] = useState();
    const [pg1, setPg1] = useState();

    useEffect(() => {
        
        instAxios.get('db_column/object?object=fees').then(res => {
            setColumns(res.data);
        });

        instAxios.get('fees/course?course=29').then(res => {
            setUg4(_.filter(res.data, ['ayear.value', '101']));
            setUg3(_.filter(res.data, ['ayear.value', '94']));
            setUg2(_.filter(res.data, ['ayear.value', '32']));
            setUg1(_.filter(res.data, ['ayear.value', '31']));
           
        });
        instAxios.get('fees/course?course=30').then(res => {
            setPg4(_.filter(res.data, ['ayear.value', '101']));
            setPg3(_.filter(res.data, ['ayear.value', '94']));
            setPg2(_.filter(res.data, ['ayear.value', '32']));
            setPg1(_.filter(res.data, ['ayear.value', '31']));
           
        });
    }, []);

    const onClickTab = (tab) => {
        setTab(tab);
    }

    const tabs = [{
        label: "Under-Graduate (UG)",
        name: "ug"
    },
    {
        label: "Post-Graduate (PG)",
        name: "pg"
    }];

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Fees Structure</h2>
                <ul className="nav nav-tabs">
                    {tabs.map((eachRow) => {
                        return (
                            <li key={eachRow.name} className="nav-item">
                                <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                            </li>
                        )
                    })}
                </ul>

                <div className="tab-content">
                    {tab === 'ug' ? (<TabAY columns={columns} data24={ug4} data23={ug3} data22={ug2} data21={ug1} />) : null}
                    {tab === 'pg' ? (<TabAY columns={columns} data24={pg4} data23={pg3} data22={pg2} data21={pg1} />) : null}
                </div>
            </div>
        </div>
    );
}

export default FeesStructure;