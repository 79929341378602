import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Navbar from "./Navbar";
import SocialIcons from './SocialIcons';

import { useTranslation } from "react-i18next";
import LanguageSwitcher from './LanguageSwitcher';
//import { menuItems } from '../menuItems';

import { getMenuObject } from '../services/helper';
import MegaMenu from './MegaMenu';
import gs from '../services/gs';

const Header = () => {
    const { t } = useTranslation();

    const [data, setData] = useState();
    const [menu, setMenu] = useState();
    const [megaMenu, setMegaMenu] = useState();

    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        gs.getByType('menu', '54').then(res => {
            setData(res);
        });
        gs.getByType('menu', '56').then(res => {
            setMegaMenu(res);
        });
    }, []);

    useEffect(() => {
        if (data !== undefined) {
            setMenu(getMenuObject(data));
        }
    }, [data]);

    return (
        <header className="header">
            <nav className="header__toolbar">
                <div className="header__start">
                    <MegaMenu data={megaMenu} />
                </div>
                <div className="header__end">
                    <LanguageSwitcher />
                    <SocialIcons />
                </div>
            </nav>
            <div className="divider -m--0 -w--100"></div>
            <nav className="main-nav">
                {/* 1st logo part */}
                <div className="logo">
                    <Link className="logo-content" to="/">
                        <div>
                            <img src='/images/logo.jpg' alt='logo' height={65} />
                        </div>
                        <div className="-ml--1 -mt--1 text-center">
                            <h3 style={{ fontSize: "18px", fontWeight: "800" }}>ಗುಲಬರ್ಗಾ ವಿಶ್ವವಿದ್ಯಾಲಯ</h3>
                            <h3 style={{ fontSize: "19px", fontWeight: "500", letterSpacing:"1px" }}>GULBARGA UNIVERSITY</h3>
                            {/* <h3>{t("gulbarga-university")}</h3>
                            <p>{t("kalaburagi")} - {t("karnataka")} - {t("india")}</p> */}
                        </div>
                    </Link>
                </div>

                {/* 2nd menu part */}
                <div className={toggle ? "mobile-menu-link" : "menu-link"}>
                    <Navbar data={menu} />
                </div>

                {/* hamburger menu start */}
                <div className='hamburger-menu'>
                    <button className='btn btn-sm' onClick={() => setToggle(!toggle)} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                </div>
            </nav>
        </header>
    );
}

export default Header;