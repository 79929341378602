
import React, { useEffect, useState } from 'react';
import ListView from '../../components/ListView';
import SubBanner from "../../components/SubHeader";
import gs from '../../services/gs';

const EResources = () => {
    const [columns, setColumns] = useState();
    const [eBooks, setEBooks] = useState();
    const [eJournals, setEJournals] = useState();
    const [linkColumns, setLinkColumns] = useState();
    const [sd, setSd] = useState(); //Subject Databases  - 89
    const [aps, setAps] = useState(); //Anti-Plagiarism Software - 86
    const [rama, setRama] = useState(); //Remote Access & Mobile App - 87
    const [osea, setOsea] = useState(); //Open Sources, e-Prints and Archives
    const [mil, setMil] = useState(); //Membership to Institutional Libraries
    const [ip, setIp] = useState(); //Institutional Repositories

    useEffect(() => {
        gs.getListColumns('implinkqty').then(res => {
            setColumns(res);
        });
        gs.getByType('implinkqty', '70').then(res => {
            setEBooks(res);
        });
        gs.getByType('implinkqty', '71').then(res => {
            setEJournals(res);
        });

        gs.getListColumns('implink').then(res => {
            setLinkColumns(res);
        });
        gs.getByType('implink', '89').then(res => {
            setSd(res);
        });
        gs.getByType('implink', '90').then(res => {
            setOsea(res);
        });
        gs.getByType('implink', '91').then(res => {
            setMil(res);
        });
        gs.getByType('implink', '92').then(res => {
            setIp(res);
        });
        gs.getByType('implink', '86').then(res => {
            setAps(res);
        });
        gs.getByType('implink', '87').then(res => {
            setRama(res);
        });

    }, []);

    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">E-Resources</h3>
                {eBooks ? (<ListView table="implinkqty" data={eBooks} columns={columns} title="Electronic Books" pageSize="10" />) : ("No data found")}
                {eJournals ? (<ListView table="implinkqty" data={eJournals} columns={columns} title="Electronic Journals" pageSize="10" />) : ("No data found")}
                {sd ? (<ListView table="implink" data={sd} columns={linkColumns} title="Subject Databases" pageSize="10" />) : ("No data found")}
                {osea ? (<ListView table="implink" data={osea} columns={linkColumns} title="Open Sources, e-Prints and Archives" pageSize="10" />) : ("No data found")}
                {mil ? (<ListView table="implink" data={mil} columns={linkColumns} title="Membership to Institutional Libraries" pageSize="10" />) : ("No data found")}
                {ip ? (<ListView table="implink" data={ip} columns={linkColumns} title="Institutional Repositories" pageSize="10" />) : ("No data found")}
                {aps ? (<ListView table="implink" data={aps} columns={linkColumns} title="Anti-Plagiarism Software" pageSize="10" />) : ("No data found")}
                {rama ? (<ListView table="implink" data={rama} columns={linkColumns} title="Remote Access & Mobile App" pageSize="10" />) : ("No data found")}
            </div>
        </>
    )
}

export default EResources;