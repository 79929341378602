import React from 'react';
import { toast } from "react-toastify";
import gs from '../services/gs';

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState('Submit')

  const onSubmit = (e) => {
    e.preventDefault()
    setFormStatus('Submitting...')
    const { name, mobile, email, message } = e.target.elements
  
    gs.sendEmail(name.value, email.value, mobile.value, message.value).then(res => {
      if(res.data === true){
        setFormStatus('Submitted');
        toast.success("Submitted");
      }else{
        setFormStatus('Submit');
        toast.error("Opps! please try after some point.");
      }
    })
  }

  return (
    <div className="container mt-5">
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input className="form-control" type="text" id="name" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Mobile
          </label>
          <input className="form-control" type="text" id="mobile" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input className="form-control" type="email" id="email" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message">
            Message
          </label>
          <textarea className="form-control" id="message" required />
        </div>
        <button className="btn btn-danger" disabled={formStatus==='Submitted'} type="submit">
          {formStatus}
        </button>
      </form>
    </div>
  )
}
export default ContactForm