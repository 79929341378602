import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import SubHeader from "../components/SubHeader";
import { useParams } from 'react-router-dom'
import EmpList from '../components/EmpList';
import { BUCKET_URL, instAxios } from '../services/helper';
import gs from '../services/gs';

const Department = () => {
    const { deptId } = useParams();
    //const cDept = departments.find(item => item.url === 'dept/' + deptId);

    const tabs = [{
        label: "Overview",
        name: "overview"
    },
    {
        label: "Programmes",
        name: "programmes"
    },
    {
        label: "Syllabus",
        name: "syllabus"
    },
    {
        label: "Research",
        name: "research"
    },
    {
        label: "Faculty",
        name: "faculty"
    },
    {
        label: "Facilities",
        name: "facilities"
    },
    {
        label: "Extension Activities",
        name: "activities"
    },
    {
        label: "Gallary",
        name: "gallary"
    }];

    const [tab, setTab] = useState('overview');
    const [overview, setOverview] = useState();
    const [clmsCOffered, setClmsCOffered] = useState();
    const [courseOffered, setCourseOffered] = useState();
    const [programmesCnt, setProgrammesCnt] = useState();
    const [syllabus, setSyllabus] = useState();
    const [syllabusCnt, setSyllabusCnt] = useState();
    const [researchCnt, setResearchCnt] = useState();
    const [facilitiesCnt, setFacilitiesCnt] = useState();
    const [activitiesCnt, setActivitiesCnt] = useState();
    const [faculty, setFaculty] = useState();
    // const [columns, setColumns] = useState();
    const [department, setDepartment] = useState();

    useEffect(() => {

        instAxios.get('/department/url?url=dept/' + deptId).then(res => {
            setDepartment(res.data);
        });
        instAxios.get('/db_column/object?object=coffered').then(res => {
            setClmsCOffered(res.data);
        });
        // instAxios.get('/db_column/object?object=employee').then(res => {
        //     setColumns(res.data);
        // });
    }, [deptId]);

    useEffect(() => {
        if (department !== undefined) {
            gs.getContent(department.overview).then(res => {
                setOverview(res.content);
            });
        }
    }, [department])

    const onClickTab = (tab) => {
        setTab(tab);
        switch (tab) {
            case "programmes":
                instAxios.get('/coffered/dept?dept=' + department.id).then(res => {
                    setCourseOffered(res.data);
                });
                gs.getContent(department.programmes).then(res => {
                    setProgrammesCnt(res.content);
                });
                break;
            case "syllabus":
                instAxios.get('/syllabus/cs?course=23&subject=' + department.subjectCode).then(res => {
                    setSyllabus(res.data);
                });
                gs.getContent(department.syllabus).then(res => {
                    setSyllabusCnt(res.content);
                });
                break;
            case "research":
                gs.getContent(department.research).then(res => {
                    setResearchCnt(res.content);
                });
                break;
            case "faculty":
                instAxios.get('/employee/department?department=' + department.id).then(res => {
                    setFaculty(res.data);
                });
                break;
            case "facilities":
                gs.getContent(department.facilities).then(res => {
                    setFacilitiesCnt(res.content);
                });
                break;
            case "activities":
                gs.getContent(department.activities).then(res => {
                    setActivitiesCnt(res.content);
                });
                break;
            case "gallary":

                break;
            default:
                break;
        }
    }

    const downloadFile = (url) => {
        window.open(BUCKET_URL + url, '_blank');
    }

    return (
        <div>
            {department && department.banner ? (<SubHeader url={department?.banner} />) : (<SubHeader />)}
            <div className="container my-4">
                {department && <h3 className="heading">{department.label}</h3>}
                <>
                    <ul className="nav nav-tabs">
                        {tabs.map((eachRow) => {
                            return (
                                <li key={eachRow.name} className="nav-item">
                                    <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                                </li>
                            )
                        })}
                    </ul>

                    <div className="tab-content">
                        {tab === 'overview' ? (
                            <div id="overview">
                                <div dangerouslySetInnerHTML={{ __html: overview }} />
                            </div>
                        ) : null}
                        {tab === 'programmes' ? (
                            <div id="programmes">
                                {courseOffered ? (<ListView table="coffered" data={courseOffered} columns={clmsCOffered} title="" pageSize="20" />) : ("No data found")}
                                {programmesCnt && <div dangerouslySetInnerHTML={{ __html: programmesCnt }} />}
                            </div>
                        ) : null}
                        {tab === 'syllabus' ? (
                            <div id="syllabus">
                                {syllabus && syllabus.map((eachSy) => {
                                    return (
                                        <div key={eachSy.id} className='row bg-light mb-2 align-middle'>
                                            <div className='col-4 align-self-center'>{eachSy.label}</div>
                                            <div className='col-2'>
                                                <button className='btn btn-outline-primary btn-sm' onClick={() => downloadFile(eachSy.url)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })}
                                {syllabusCnt && <div dangerouslySetInnerHTML={{ __html: syllabusCnt }} />}
                            </div>
                        ) : null}
                        {tab === 'research' ? (
                            <div id="research">
                                {researchCnt && <div dangerouslySetInnerHTML={{ __html: researchCnt }} />}
                            </div>
                        ) : null}
                        {tab === 'faculty' ? (
                            <div id="faculty">
                                {faculty ? (<EmpList data={faculty} />) : ("No data found")}
                            </div>
                        ) : null}
                        {tab === 'facilities' ? (
                            <div id="facilities">
                                {facilitiesCnt && <div dangerouslySetInnerHTML={{ __html: facilitiesCnt }} />}
                            </div>
                        ) : null}
                        {tab === 'activities' ? (
                            <div id="activities">
                                {activitiesCnt && <div dangerouslySetInnerHTML={{ __html: activitiesCnt }} />}
                            </div>
                        ) : null}
                    </div>
                </>
            </div>
        </div>
    );
}

export default Department;