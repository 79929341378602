import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import ListView from '../../components/ListView';
import authHeader from '../../services/auth-header';
import gs from '../../services/gs';
import { Link } from "react-router-dom";

const AdminListView = () => {
    const { tableId } = useParams();
    const [dbObject, setDbObject] = useState();
    const [data, setData] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getSecureByName('db_object', tableId).then(res => {
            setDbObject(res);
        });
    }, [tableId]);

    useEffect(() => {
        if (dbObject !== undefined) {
            gs.getListColumns(dbObject.name).then(res => {
                setColumns(res);
            });
            if (tableId !== 'user') {
                gs.getAll(dbObject.name).then(res => {
                    setData(res);
                });
            } else {
                gs.getSecureAll(dbObject.name, { headers: authHeader() }).then(res => {
                    setData(res);
                });
            }
        }
    }, [dbObject]);

    return (
        <div className='containr'>
            <div className='d-flex justify-content-between align-items-center form-header my-2 py-1'>
                {dbObject && <div className='ps-2 default-heading'>{dbObject.label}</div>}
                <div className=''>
                    {dbObject && <Link className="btn btn-outline-primary btn-sm" to={`/admin/form/${dbObject.name}`}>New</Link>}
                </div>
            </div>

            {data && (
                <ListView table={tableId} data={data} columns={columns} title="" showFilter="true" createRow="false" editRow="false" viewRow="true" deleteRow="false" pageSize="20" />
            )}
        </div>
    )
}
export default AdminListView;