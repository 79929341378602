import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import SubHeader from "../components/SubHeader";
import gs from '../services/gs';

const UUCMS = () => {
    const [editorState, setEditorState] = useState();
    const [posts, setPosts] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getContent('28').then(res => {
            setEditorState(res.content);
        });

        gs.getListColumns('notification').then(res => {
            setColumns(res);
        });

        gs.getByType('notification', 78).then(res => {
            setPosts(res);
        });
    }, []);

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h3 className="heading">UUCMS (Unified University College Management System)</h3>
                <div dangerouslySetInnerHTML={{ __html: editorState }} />
                <ListView table="notification" data={posts} columns={columns} title="Notifications" download="true" pageSize="20" />
            </div>
        </div>
    );
}

export default UUCMS;