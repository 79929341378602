import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import TabUGPG from '../components/TabUGPG';
import gs from '../services/gs';

const Notifications = () => {
    const [tab, setTab] = useState('administration');
    const [adm, setAdm] = useState();
    const [acadUG, setAcadUG] = useState();
    const [acadPG, setAcadPG] = useState();
    const [examUG, setExamUG] = useState();
    const [examPG, setExamPG] = useState();
    const [sport, setSport] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getListColumns('notification').then(res => {
            setColumns(res);
        });

        gs.getByType('notification', '7').then(res => {
            setAdm(res);
        });
    }, [])

    const onClickTab = (tab) => {
        setTab(tab);
        if (tab === "administration") {
            gs.getByType('notification', 7).then(res => {
                setAdm(res);
            });
        }
        if (tab === "academic") {
            gs.getByType('notification', 8).then(res => {
                setAcadUG(res);
            });
            gs.getByType('notification', 9).then(res => {
                setAcadPG(res);
            });
        }
        if (tab === "examination") {
            gs.getByType('notification', 10).then(res => {
                setExamUG(res);
            });
            gs.getByType('notification', 11).then(res => {
                setExamPG(res);
            });
        }
        if (tab === "sports") {
            gs.getByType('notification', 12).then(res => {
                setSport(res);
            });
        }
    }

    const tabs = [{
        label: "Administration",
        name: "administration"
    },
    {
        label: "Academic",
        name: "academic"
    },
    {
        label: "Examination",
        name: "examination"
    },
    {
        label: "Sports",
        name: "sports"
    }];

    return (
        <div className="container my-4">
            <h3 className="heading">Notifications</h3>
            <>
                <ul className="nav nav-tabs">
                    {tabs.map((eachRow) => {
                        return (
                            <li key={eachRow.name} className="nav-item">
                                <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                            </li>
                        )
                    })}
                </ul>

                <div className="tab-content">
                    {tab === 'administration' ? (
                        adm && <ListView table="notification" data={adm} columns={columns} title="" download="true" pageSize="20" />
                    ) : null}
                    {tab === 'academic' ? (
                        acadUG && <TabUGPG columns={columns} ugData={acadUG} pgData={acadPG} />
                    ) : null}
                    {tab === 'examination' ? (
                        examPG && <TabUGPG columns={columns} ugData={examUG} pgData={examPG} />
                    ) : null}
                    {tab === 'sports' ? (
                        sport && <ListView table="notification" data={sport} columns={columns} title="" download="true" pageSize="20" />
                    ) : null}
                </div>
            </>
        </div>
    )
}

export default Notifications;