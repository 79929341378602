import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import gs from '../services/gs';

const AboutSectionVC = () => {
    const [content, setContent] = useState();

    useEffect(() => {
        gs.getContent('118').then(res => {
            setContent(res.content);
        });
    }, []);

    return (
        <div className="container" data-aos="fade-up">
            <h2 className="heading">Vice-Chancellor's Message</h2>
            <div className="flex-container">
                <div className="img-child-container" style={{ textAlign: 'center' }}>
                    <img src="/images/vclandscape.jpg" className="w-100" alt="Vice Chancellor" />
                    <br />
                    <span className="default-heading">Prof. Dayanand Agsar</span><br />
                    Vice Chancellor
                </div>
                <div className="text-child-container">
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                    <Link className="read-more-btn" data-aos="fade-left" to="/page/vice-chancellor">
                        Read More
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default AboutSectionVC;