import React, { useState } from 'react';
import ListView from "./ListView";

const TabAY = ({ columns, data24, data23, data22, data21 }) => {
    const [tab, setTab] = useState('2024');

    const onClickTab = (tab) => {
        setTab(tab);
    }

    const tabs = [
        {
            label: "Academic Year 2023-24",
            name: "2024"
        },
        {
            label: "Academic Year 2022-23",
            name: "2023"
        },
        {
            label: "Academic Year 2021-22",
            name: "2022"
        },
        {
            label: "Academic Year 2020-21",
            name: "2021"
        },
    ];

    return (
        <>
            <ul className="nav nav-tabs">
                {tabs.map((eachRow) =>
                    <li key={eachRow.name} className="nav-item">
                        <a className={tab === eachRow.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachRow.name)}>{eachRow.label}</a>
                    </li>
                )}
            </ul>

            <div className="tab-content">
                {tab === '2024' && (
                    <div id="2024">
                        {data24 ? (<ListView table="faculty" data={data24} columns={columns} download="true" pageSize="20" />) : ("No data found")}
                    </div>
                )}
                {tab === '2023' && (
                    <div id="2023">
                        {data23 ? (<ListView table="faculty" data={data23} columns={columns} download="true" pageSize="20" />) : ("No data found")}
                    </div>
                )}
                {tab === '2022' && (
                    <div id="2022">
                        {data22 ? (<ListView table="faculty" data={data22} columns={columns} download="true" pageSize="20" />) : ("No data found")}
                    </div>
                )}
                {tab === '2021' && (
                    <div id="2021">
                        {data21 ? (<ListView table="faculty" data={data21} columns={columns} download="true" pageSize="20" />) : ("No data found")}
                    </div>
                )}
            </div>
        </>
    )
}

export default TabAY;