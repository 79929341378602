import React, { useEffect, useState } from 'react';
import EmpList from '../../components/EmpList';
import SubBanner from "../../components/SubHeader";
import gs from '../../services/gs';

const LibraryStaff = () => {

    const [faculty, setFaculty] = useState();

    useEffect(() => {
        gs.getEmpByDept('39').then(res => {
            setFaculty(res);
        });
    }, []);

    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">Library Staff</h3>
                <EmpList data={faculty} />
            </div>
        </>
    )
}

export default LibraryStaff;