import SubBanner from "../../components/SubHeader";

const ICTFS = () => {
    return (
        <div>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">ICT FACILITIES & SERVICES</h3>
                <div className="heading default-heading">VIRTUAL LEARNING RESOURCE CENTRE & DIGITAL LIBRARY</div>
                <p className="-ta--j">The Virtual Learning Resource Centre & Digital Library (VLRC&DL) at Gulbarga University reflects the University’s mission to be a leading “Learning Region” incorporating current state-of-the–art ICT facilities established by Infosys Foundation, Bangalore.</p>
                <p className="-ta--j">VLRC&DL has 100 workstations, three Mainframe DELL Servers and CD NAS server with a carpet area of 12,420 sq. feet using One GBPS Leased line provides excellent Internet facilities, access to UGC Infonet e-journals, online databases, e-books, learning courseware's and institutional publications.</p>
                <img src="/images/library/3_VLRCDL.jpg" alt="Vice-Chancellor" width="450rem" />
                <br />
                <br />
                <div className="heading default-heading">UGC Infonet E-Journals, Databases and E-Books</div>
                <p className="-ta--j">About 10,000 plus e-Journals and databases under UGC Infonet consortia that includes major publishers like Taylor & Francis, American Chemical Society, Springer link, JSTOR, Wiley, Oxford and Cambridge University Press, Blackwell, Web of Science and other e-resources can be accessed on campus network and remotely by users. E-Books (Springer, Proquest and McGraw-Hill collections) can be accessed under network environment.</p>
                <img src="/images/library/UGC_Infonet.jpg" alt="Vice-Chancellor" width="450rem" />
                <br />
                <br />
                <div className="heading default-heading">Upgradation of Virtual Learning Resource Centre & Digital Library</div>
                <p className="-ta--j">Upgradation of Virtual Learning Resource Centre & Digital Library - supported by Kalyana Karnataka Human Resources, Agriculture & Cultural Association to the tone of one crore inaugurated by Hon'ble Higher Education Minister on 28.04.2021</p>
                <img src="/images/library/Upgradation_VLRCDL.jpg" alt="Vice-Chancellor" width="450rem" />
                <br />
                <br />
                <div className="heading default-heading">Competitive Examinations and Personality Development Centre</div>
                <p className="-ta--j">Specialized and updated collection in all subjects for UGC NET/ JRF and other competitive examinations are made available for students in addition other competitive examinations. Documents required for preparing to various recruitments, common entrance examinations and competitive examinations also have been collected and updated regularly. E-books on competitive exams can be accessed at http://gulbargauniversity.mintbook.com/</p>

                <div className="heading default-heading">Kalyana (Hyderabad) Karnataka Resource Centre - Digital Knowledge Project</div>
                <p className="-ta--j">Efforts are on to develop print, online, digital and live recordings pertaining to economical, political, cultural, social, customs, educational, folk wisdom, folk medicine and this Centre supports information to conduct research and development on HK region. An initiative to provide e-books in English and Kannada language to support for Competitive Exams, Personality Development and Capacity building to the rural and urban society of Hyderabad Karnataka region including Kannada University, Hampi and Vijayanagara Sri Krishnadevaraya University, Bellary administered by Gulbarga University Library connecting about 1000 digital libraries. This is an attempt towards the 'Digital India' campaign, aimed at creating a digitally empowered society and knowledge economy. This is accessible @ http://gulbargauniversity.mintbook.com/</p>

                <div className="heading default-heading">Reprography, Printing and Document Delivery Services</div>
                <p className="-ta--j">Reprography service is available to the users on charge through outsourcing. Even fee based printing is facilitated to the users for the information/ learning materials searched on the net. Document delivery of articles required is provided through mail service.</p>

                <div className="heading default-heading">WEB-OPAC - Online Catalogue and FEdGATE Discovery Tool</div>
                <p className="-ta--j">Library Catalogue is accessible online at http://libcat-guglib.informindia.co.in/ which uses KOHA software for library automation under cloud computing. FedGate a federated discovery service enables users to simultaneously search Library Catalogue, E-Journals, E-Books, Databases, Institutional databases and Publishers databases in real time from multiple information resources in a single search.</p>

                <div className="heading default-heading">Institutional Repository (http://gukir.inflibnet.ac.in/)</div>
                <p className="-ta--j">Institutional Scientific Productivity of the University has faculty research publications (438 plus), Question bank (Semester I to IV/VI) of current academic year in all the disciplines of Science, Social Sciences, Humanities, Commerce and Management, Law and Education. Besides, the information and reports pertaining to Hyderabad Karnataka region, Statistical reports, Thesis and dissertations, free e-books and learning course materials are accessible which are regularly updated. The institutional repository may be access through web site: http://gukir.inflibnet.ac.in/</p>

                <div className="heading default-heading">Shodhganga- Indian ETD Repository of Doctoral Thesis, INFLIBNET Centre</div>
                <p className="-ta--j">The library has been contributing Gulbarga PhD thesis at http://shodhganga.inflibnet.ac.in/ and as on date 227 PhD Thesis have been uploaded on Shodhganga and old retrospective digitization of thesis is in process. The Shodhganga ETD of Gulbarga University can be accessed at https://shodhganga.inflibnet.ac.in/handle/10603/8838</p>

                <div className="heading default-heading">Remote Access Facility</div>
                <p className="-ta--j">Remote Access Facility using Knimbus Gateway and Mobile App has been extended wherein each user is given login name and password to access library e-resources remotely by clicking on remote access available @ www.guglibrary.net</p>

                <div className="heading default-heading">Question Point service from OCLC, USA</div>
                <p className="-ta--j">'ASK a Librarian' service to answer the information queries and reference questions of any users around the world at ( http://guglibrary.net/ask-librarian.aspx).</p>

                <div className="heading default-heading">Videoconferencing facility</div>
                <p className="-ta--j">Videoconferencing facility provides platform for interactive lectures, demonstrations, educational films and other e-resources for the academics and is used extensively which is IP enabled accessed @ 14.139.156.107.</p>

                <div className="heading default-heading">Wi-Fi Facility and CCTV Surveillance System</div>
                <p className="-ta--j">Wi-fi (Wireless Fidelity) access is available across the library and users can bring their laptop for accessing e-information. CCTV Surveillance installed helps security control in the library.</p>

                <div className="heading default-heading">B. ACCESS TO CD-ROM DATABASES</div>
                <p className="-ta--j">Gulbarga University Library is one of the first Universities to adopt this technology using CD NET Tower for multi-user access in India. At present, the following sixteen national and international databases subscribed till 2003 in various disciplines available under CD NAS Server by creating images, accessible under network.</p>
                <table className="light-table" cellpadding="4" cellspacing="1" bgcolor="#dedede">
                    <tbody>
                        <tr className="rHdr" bgcolor="#efefef">
                            <td className="col0">Database</td>
                            <td className="col1">Period</td>
                            <td className="col2">Mode of updates</td>
                            <td className="col3">Publishers</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Math-Sci.</td>
                            <td className="col1">1980+2001</td>
                            <td className="col2">Semi Annual</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Biological Abstracts</td>
                            <td className="col1">1992+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Biotechnology Abstracts</td>
                            <td className="col1">1982+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Sociological Abstracts</td>
                            <td className="col1">1974+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Econ-Lit</td>
                            <td className="col1">1969+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Psych-Info</td>
                            <td className="col1">1887+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Cross Culture</td>
                            <td className="col1">Vol. 1-5</td>
                            <td className="col2">One Time</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Georef</td>
                            <td className="col1">1785+2000</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Eric</td>
                            <td className="col1">1966+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Silver Platter, USA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">LISA</td>
                            <td className="col1">1969+2002</td>
                            <td className="col2">Monthly</td>
                            <td className="col3">CSA</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Dissertation Abstracts Intnl.</td>
                            <td className="col1">1861+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">UMI, U.K</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">ABI/Inform</td>
                            <td className="col1">1989+2002</td>
                            <td className="col2">Monthly</td>
                            <td className="col3">UMI, U.K</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">INSPEC</td>
                            <td className="col1">1989+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">I.E.E.E</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">CABSAC (South Asian)</td>
                            <td className="col1">1973+2000</td>
                            <td className="col2">Yearly</td>
                            <td className="col3">Informatics India Pvt. Ltd.</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">IBID</td>
                            <td className="col1">1993+2001</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Informatics India Pvt. Ltd.</td>
                        </tr>
                        <tr bgcolor="#ffffff">
                            <td className="col0">Supreme Court Online</td>
                            <td className="col1">1950+-2000</td>
                            <td className="col2">Quarterly</td>
                            <td className="col3">Eastern Book Co., In</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <div className="heading default-heading">C. LIBRARY AUTOMATION & WEB OPAC</div>
                <p className="-ta--j">Cloud Computing Technology has been adopted for all the in-house activities and services to facilitate proper library database management using KOHA Library software and Web OPAC is available @ http://libcat-guglib.informindia.co.in/</p>
                <div className="heading default-heading">D. GULBARGA UNIVERSITY SHARANA SAHITYA LIBRARY AND RESOURCE CENTRE</div>
                <p className="-ta--j">A library with carpet area of 40,000 sq. ft has been built in the heart of Basavakalyan city established and inaugurated on 26th February 2011. The mission of the Library is to build a rich and strong resourceful reference library in the Kayakabhoomi of Jagajyoti Shri Basaveshwara in the areas of Sharana Sahitya, Vachana Sahitya, Sharana-Sharaneyaru, Srikshetras, Veerashaiva history – culture - comparative studies on religion and other related areas to support advanced studies and research [http://www.guglibrary.net/ssl/].</p>
                <div className="heading default-heading">F. EXISTING STATE-OF-THE ART ICT FACILITIES IN VLRC & DL</div>
                <table className="light-table">
                    <tr bgcolor="#efefef">
                        <td>Infrastructure</td>
                        <td>Specification</td>
                        <td>Quantity</td>
                    </tr>
                    <tr><td>Personal Computers and Laptops</td>
                        <td>Lenovo Make, Pentium Dual Core, 150GB Hard Disk, 1GB RA;
                            Lenovo Make Intel Core i3,4GB RAM , 465 GB Hard Disk;
                            HP DESKTOP PROONE 400 G6, 8GB, 512 GB</td>
                        <td>140</td>
                    </tr>
                    <tr>
                        <td>Main Frame Servers</td>
                        <td>Dell™ Power edge ™ 2900 Quad Core Intel® Xeon® E5 310, 2x4MB Cache Server,</td>
                        <td>3</td>
                    </tr >
                    <tr>
                        <td>Data Redundant Server</td>
                        <td>HP Store Easy 1640 Storage, 20TB Hard Disk, 16GB RAM, Xeon® E5 - 2407</td >
                        <td>1</td >
                    </tr >
                    <tr>
                        <td>CD NAS Server</td>
                        <td>2084 MB memory, DVD writer drive, 500 GB SATA Hard Disk Drive</td >
                        <td>1</td >
                    </tr >
                    <tr>
                        <td> Kindle Readers - Tab</td>
                        <td>Kindle and Samsung</td >
                        <td>15</td >
                    </tr >
                    <tr>
                        <td>RFID Gates</td>
                        <td>RFID Gates and Work Station</td >
                        <td>3</td >
                    </tr >
                    <tr>
                        <td>High End Scanner</td>
                        <td>ATIZ BookDrive Pro, PS 7000 Minolta</td >
                        <td>2</td >
                    </tr >
                    <tr>
                        <td>Digital Network Printers</td>
                        <td>Phaser 4510</td >
                        <td>3</td >
                    </tr >
                    <tr>
                        <td>Videoconference Units</td>
                        <td>Polycom VSX 7000, Draper Baronet Remote Mt of Videoconference / LCD units</td >
                        <td>1</td >
                    </tr >
                    <tr>
                        <td>Led Displays</td>
                        <td>LED BenQ 42</td >
                        <td>5</td >
                    </tr >
                    <tr>
                        <td>Bar Code Printer (1) & Laser Guns (2)</td>
                        <td>LP2844 Printer, Heron D130</td >
                        <td>4</td >
                    </tr >
                    <tr>
                        <td>KOHA- Library Automation Software</td>
                        <td>Koha 3.22.4 open source Integrated Library Management System under Cloud Enabled Technology</td >
                        <td>1</td >
                    </tr >
                    <tr>
                        <td>Statistical Software</td>
                        <td>Systat</td >
                        <td>1</td >
                    </tr >
                    <tr>
                        <td>Open Source Software's</td>
                        <td>Ubuntu, DSpace, Koha</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>Firewall</td>
                        <td>SONICWALL</td>
                        <td>1</td>
                    </tr>
                </table >
            </div>
        </div>
    )
}

export default ICTFS;