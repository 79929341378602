import SubBanner from "../../components/SubHeader";

const Competitive = () => {
    return (
        <>
             <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">Competitive Centre</h3>
                <p className="-ta--j">Competitive Examinations and Career Development Information Resource Center (CECD IRC) has been established to help our students to successfully pass through various competitive examinations. For this purpose, fair collection of books, periodicals and other documents on Competitive Examinations, Career Planning, Personality Development, Communication skills etc have been stacked. Documents required for preparing to various recruitments, common entrance examinations and competitive examinations have been collected and updated regularly for reference by Students and staff. Rich resources provide information on career planning, personality development and skills development. Self-study and learning packages provide great opportunities for independent computer assisted learning. Online links for career portals, scholarship and counselling are provided.</p>
                <p className="-ta--j">For preparation of Competitive Exams, two online portals are subscribed, which can be accessed by students.</p>
                <ul>
                    <li>KOPYKITAB: Competitive Master (http://gugk.kopykitab.com/)</li>
                    <li>Exam Master Mind (https://www.exammastermind.com/)</li>
                </ul>
                <img src="/images/library/competitive.jpg" alt="Vice-Chancellor" width="450rem" />
            </div>
        </>
    )
}

export default Competitive;