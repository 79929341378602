import { Link } from 'react-router-dom';

const SectionLibrarian = () => {
    return (
        <div className="container" data-aos="fade-up">
            <h2 className="heading">Librarian's Message</h2>
            <div className="row">
                <div className="col-12">
                    <p className="-ta--j">Gulbarga University is one of the leading centers of academic excellence caters to the educational needs of the society located in the developing region of Kalyan Karnataka established in 10-09-1980, has a sprawling green campus of over 800 acres offering . Gulbarga University Library, a Knowledge Centre for accessibility developed on modern lines as a prominent Learning Resource Centre on sprawling Campus with Open Air Green Library, ideally situated and easily accessible by all the departments and students housing with a carpet area of 7,525 Sq. Meters with seating capacity of 800 users. The University Library, makes sincere efforts to provide user focused services having over 2.5 lakh books, subscribes to 2500 scientific print & online journals, 5400 e-books, sixteen CD-ROM Archival. More than 1100 Ph.D e-Theses, 7200+ printed dissertation/theses and technical reports, microfilm, microfiche, conference papers and rare books. The Library is at the core of research and learning at the University and automated its in-house activities and services using KOHA – integrated open source software using cloud technology and implemented RFID Technology....</p>
                    <Link className="read-more-btn align-right" data-aos="fade-left" to="librarian-msg">
                        Read More
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default SectionLibrarian;