import React, { useState } from 'react';
import { ApiServiceCreate, ApiServiceUpdate } from "../../services/api-service";
import { toast } from "react-toastify";
import Editor from '../../components/Editor';
import { instAxios } from '../../services/helper';

const TextEditor = () => {
    const [data, setData] = useState();
    const [editorState, setEditorState] = useState();
    const [id, setId] = useState();
    const [label, setLabel] = useState();
    const [name, setName] = useState();
    const [active, setActive] = useState();

    const onSearch = () => {
        instAxios.get('/content/' + id).then(res => {
            setData(res.data);
            setId(res.data.id);
            setLabel(res.data.label);
            setName(res.data.name);
            setEditorState(res.data.content);
            setActive(res.data.active);
        });
    }

    const onSave = (action) => {
        const newObj = {
            "id": id,
            "label": label,
            "name": name,
            "content": editorState,
            "active": active
        }
        if (action === "save") {
            ApiServiceCreate(newObj, "content").then((resp) => {
                toast.success("Record created");
            }).catch((error) => {
                console.log(error);
                toast.error(error.message);
            });
        } else if (action === "update") {
            ApiServiceUpdate(newObj, "content").then((resp) => {
                toast.success("Record updated");
            }).catch((error) => {
                console.log(error);
                toast.error(error.message);
            });
        }
    }

    const handleTextChange = event => {
        setEditorState(event.target.value);
    }

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center form-header my-2'>
                {data && <div className='ps-2 default-heading'>{data.label}</div>}
                <div className=''>
                    <button className="btn btn-primary btn-sm" onClick={() => onSave("update")}>Save</button>
                </div>
            </div>
            <div className='row my-1'>
                <div className='col-4 d-flex'>
                    <div className='col-2'>Id</div>
                    <div className='col-2 d-flex'>
                        <input id='label' value={id} onChange={(e) => setId(e.target.value)} />
                        <button className="btn btn-primary btn-sm ms-1" onClick={() => onSearch()}>Search</button>
                    </div>
                </div>
            </div>
            <div className='row my-1'>
                <div className='col-4 d-flex'>
                    <div className='col-2'>Label</div>
                    <div className='col-2'><input id='label' value={label} onChange={(e) => setLabel(e.target.value)} /></div>
                </div>
                <div className='col-4 d-flex'>
                    <div className='col-2'>Name</div>
                    <div className='col-2'><input id='name' value={name} onChange={(e) => setName(e.target.value)} /></div>
                </div>
                <div className='col-4 d-flex'>
                    <div className='col-2'>Active</div>
                    <div className='col-2'><input type="checkbox" className="checkbox" checked={active} value={active} onChange={(e) => setActive(e.target.value)} /></div>
                </div>
            </div>
            <div>Content</div>
            <Editor
                editorState={editorState}
                onEditorStateChange={handleTextChange}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
            />
            <div className='default-heading mt-2'>Preview</div>
            <div className='dsihtml' >
                <div dangerouslySetInnerHTML={{ __html: editorState }} />
            </div>
        </div>
    );
}

export default TextEditor;