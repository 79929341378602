import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import gs from '../../services/gs';
import Select from '../../components/Select';
import { ApiServiceCreate, ApiServiceUpdate } from "../../services/api-service";
import { toast } from "react-toastify";

const AdminFormView = () => {

    const navigate = useNavigate();

    const { table, Id } = useParams();
    const [id, setId] = useState(Id);
    const [action, setAction] = useState();
    const [data, setData] = useState();
    const [dbObject, setDbObject] = useState();
    const [sections, setSections] = useState();
    const [sectionsWD, setSectionsWD] = useState();

    useEffect(() => {
        gs.getSecureByName('db_object', table).then(res => {
            setDbObject(res);
        });
    }, [table, id]);

    useEffect(() => {
        if (Id !== undefined) {
            gs.getById(table, id).then(res => {
                setData(res);
                setAction('update');
            });
        } else {
            setData(null);
            setAction('save');
        }
    }, [id]);

    useEffect(() => {
        if (dbObject !== undefined) {
            gs.getSections(dbObject.id).then(res => {
                setSections(res);
            });
        }
    }, [dbObject]);

    useEffect(() => {
        if (sections !== undefined) {
            sections.forEach((eachSec) => {
                eachSec.columns.map((col) => {
                    if (action === 'update')
                        col.data = data[col.name];
                    else
                        col.data = '' // Set new field
                });
            });
            setSectionsWD(sections);
        }
    }, [sections]);

    const onSave = () => {
        var maping = [];
        sectionsWD.forEach((eachSec) => {
            maping = maping.concat(eachSec.columns.map(item => ({ [item.name]: item.data })));
        });

        if (action === "update") {
            const objId = { id: Id } // resp will contain newly created Id
            maping.push(objId); // required as we are not showing in the column
        }

        var newObj = Object.assign({}, ...maping);

        if (action === "update") {
            ApiServiceUpdate(newObj, dbObject.name).then((resp) => {
                toast.success("Record updated");
            }).catch((error) => {
                console.log(error);
                toast.error(error.message);
            });
        } else {
            ApiServiceCreate(newObj, dbObject.name).then((resp) => {
                toast.success("Record created");
            }).catch((error) => {
                console.log(error);
                toast.error(error.message);
            });
        }
    }
    const onNew = () => {
        navigate('/admin/form/' + dbObject.name);
        setId(null);
        setAction('new');
        sections.forEach((eachSec) => {
            eachSec.columns.map((col) => {
                col.data = undefined // Set new field
            });
        });
        setSectionsWD(sections);
    }

    const onUpdate = () => {
        setAction('update');
        onSave();
    }

    const onDelete = () => {
        gs.deleteRow(table, Id).then((resp) => {
            toast.success("Record Deleted");
        }).catch((error) => {
            console.log(error);
            toast.error(error.message);
        });
    }

    const handleChange = (event, property, secId) => {
        const index = sectionsWD.findIndex(item => item.id === secId);
        let temp = [...sectionsWD]; // important to create a copy, otherwise you'll modify state outside of setState call
        let colIdx = temp[index].columns.findIndex(item => item.name === property.name);

        if (property.type === "Options" || property.type === "Reference") {
            temp[index].columns[colIdx].data = {
                label: event.target[event.target.selectedIndex].text, value: event.target.value
            };
        }
        else if (property.type === "Boolean") {

            temp[index].columns[colIdx].data = !temp[index].columns[colIdx].data;
        }
        else {
            temp[index].columns[colIdx].data = event.target.value;
        }
        setSectionsWD(temp);
    }

    const renderSwitch = (eachRow, secId) => {
        switch (eachRow.type) {
            case 'Reference':
            case 'Options':
                return (
                    <Select eachRow={eachRow} handleChange={(e, eachRow) => handleChange(e, eachRow, secId)} />
                )
            case 'Boolean':
                return <input type="checkbox" className="checkbox" checked={eachRow.data} value={eachRow.data} onChange={(e) => handleChange(e, eachRow, secId)} />
            case 'Integer':
                return <input type="number" className="form-control" value={typeof eachRow.data !== 'object' ? eachRow.data : ""} onChange={(e) => handleChange(e, eachRow, secId)} />
            default:
                return <input type="text" className="form-control" value={typeof eachRow.data !== 'object' ? eachRow.data : ""} onChange={(e) => handleChange(e, eachRow, secId)} />
        }
    }

    return (
        <div className='container'>
            <div className='d-flex justify-content-between align-items-center form-header my-2 py-1'>
                {dbObject && <div className='ps-2 default-heading'>{dbObject.label}</div>}
                <div className=''>
                    <button className="btn btn-outline-primary btn-sm" onClick={() => onNew("new")}>New</button>
                    <button className="btn btn-outline-primary btn-sm" onClick={() => onSave("save")}>Save</button>
                    <button className="btn btn-outline-primary btn-sm" onClick={() => onUpdate("update")}>Update</button>
                    <button className="btn btn-outline-primary btn-sm" onClick={() => onDelete("delete")}>Delete</button>
                </div>
            </div>
            {sectionsWD && sectionsWD.map((eachSec) => {
                return (
                    <div className='container' key={eachSec.id}>
                        {eachSec.type.label === 'Col1' ? (
                            <div className='row'>
                                <div className='col-xs-12'>
                                    {eachSec.columns && eachSec.columns.map((eachCol) => {
                                        return (
                                            <div key={eachCol.id} className="form-group">
                                                <label className="col-xs-12 col-md-1_5 col-lg-2 control-label">{eachCol.label}</label>
                                                <div className="col-xs-10 col-md-9 col-lg-9 form-field input_controls">
                                                    {renderSwitch(eachCol, eachSec.id)}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        ) : (
                            <div className='row'>
                                <div className='col-sm-6'>
                                    {eachSec.columns && eachSec.columns.map((eachCol1) => {
                                        return (
                                            eachCol1.columntype.label === 'Col1' ? (
                                                <div key={eachCol1.id} className="form-group">
                                                    <label className="col-xs-12 col-md-3 col-lg-4 control-label" >{eachCol1.label}</label>
                                                    <div className="col-xs-10 col-sm-9 col-md-6 col-lg-6 form-field input_controls">
                                                        {renderSwitch(eachCol1, eachSec.id)}
                                                    </div>
                                                </div>
                                            ) : null
                                        )
                                    })}
                                </div>
                                <div className='col-sm-6'>
                                    {eachSec.columns && eachSec.columns.map((eachCol2) => {
                                        return (
                                            eachCol2.columntype.label === 'Col2' ? (
                                                <div key={eachCol2.id} className="form-group">
                                                    <label className="col-xs-12 col-md-3 col-lg-4 control-label" >{eachCol2.label}</label>
                                                    <div className="col-xs-10 col-sm-9 col-md-6 col-lg-6 form-field input_controls">
                                                        {renderSwitch(eachCol2, eachSec.id)}
                                                    </div>
                                                </div>
                                            ) : null
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                )
            })}
        </div >
    )
}
export default AdminFormView;