import React, { useEffect, useState } from 'react';
import SubHeader from "../components/SubHeader";
import ListView from '../components/ListView';
import _ from "lodash";
import gs from '../services/gs';

const CollegeList = () => {

    const [kalaburagi, setKalaburagi] = useState();
    const [bidar, setBidar] = useState();
    const [tab, setTab] = useState('kalaburagi');
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getListColumns('college').then(res => {
            setColumns(res);
        });
        gs.getAll('college').then(res => {
            var klb = _.filter(res, ['district', 'K']);
            setKalaburagi(klb);
            var bid = _.filter(res, ['district', 'B']);
            setBidar(bid);
        });
    }, []);

    const onClickTab = (tab) => {
        setTab(tab);
    }

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Affiliated Colleges</h2>

                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={tab === 'kalaburagi' ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab('kalaburagi')}>Kalaburagi</a>
                    </li>
                    <li className="nav-item">
                        <a className={tab === 'bidar' ? "nav-link active" : "nav-link"} onClick={() => onClickTab('bidar')}>Bidar</a>
                    </li>
                </ul>

                <div className="tab-content">
                    {tab === 'kalaburagi' ? (
                        <div id="Kalaburagi">
                            {kalaburagi ? (<ListView table="college" data={kalaburagi} columns={columns} title="Colleges" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : (
                        <div id="Bidar">
                            <ListView table="college" data={bidar} columns={columns} title="Colleges" pageSize="20" />
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
};

export default CollegeList;