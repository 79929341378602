import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {

  const { i18n } = useTranslation();

  return (
      <div className="select">
          <select className="language-box"
        value={i18n.language}
        onChange={(e) =>
          i18n.changeLanguage(e.target.value)
        }
      >
        <option value="en">English</option>
        <option value="kn">ಕನ್ನಡ</option>
      </select>
    </div>
  );

}

export default LanguageSwitcher;