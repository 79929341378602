import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import gs from '../services/gs';
import SubHeader from "../components/SubHeader";

const OldAnnouncements = () => {
    const [tab, setTab] = useState('academic');
    const [columns, setColumns] = useState();
    const [academic, setAcademic] = useState();
    const [admission, setAdmission] = useState();
    const [admin, setAdmin] = useState();
    const [affiliation, setAffiliation] = useState();
    const [exam, setExam] = useState();
    const [important, setImportant] = useState();
    const [news, setNews] = useState();

    useEffect(() => {
        gs.getListColumns('notification').then(res => {
            setColumns(res);
        });
        gs.getByType('notification', 79).then(res => {
            setExam(res);
        });
    }, []);

    const onClickTab = (tab) => {
        setTab(tab);
        switch (tab) {

            case "academic":
                gs.getByType('notification', 80).then(res => {
                    setAcademic(res);
                });
                break;
            case "admission":
                gs.getByType('notification', 81).then(res => {
                    setAdmission(res);
                });
                break;

            case "admin":
                gs.getByType('notification', 82).then(res => {
                    setAdmin(res);
                });
                break;
            case "affiliation":
                gs.getByType('notification', 83).then(res => {
                    setAffiliation(res);
                });
                break;
            case "exam":
                gs.getByType('notification', 79).then(res => {
                    setExam(res);
                });
                break;
            case "important":
                gs.getByType('notification', 84).then(res => {
                    setImportant(res);
                });
                break;
            case "news":
                gs.getByType('notification', 85).then(res => {
                    setNews(res);
                });
                break;
            default:
                break;
        }
    }

    const tabs = [
        {
            label: "ACADEMIC",
            name: "academic"
        },
        {
            label: "ADMISSION",
            name: "admission"
        },
        {
            label: "ADMINISTRATION",
            name: "admin"
        },
        {
            label: "AFFILIATION",
            name: "affiliation"
        },
        {
            label: "EXAMINATION",
            name: "exam"
        },
        {
            label: "IMPORTANT LINKS",
            name: "important"
        },
        {
            label: "NEWS & EVENTS",
            name: "news"
        },
    ];

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Old Announcements</h2>

                <ul className="nav nav-tabs">
                    {tabs.map((eachTab) => {
                        return (
                            <li key={eachTab.name} className="nav-item">
                                <a className={tab === eachTab.name ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => onClickTab(eachTab.name)}>{eachTab.label}</a>
                            </li>
                        )
                    })}
                </ul>

                <div className="tab-content">

                    {tab === 'academic' ? (
                        <div id='academic'>
                            {academic ? (<ListView table="notification" data={academic} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                    {tab === 'admission' ? (
                        <div id='admission'>
                            {admission ? (<ListView table="notification" data={admission} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                    {tab === 'admin' ? (
                        <div id='admin'>
                            {admin ? (<ListView table="notification" data={admin} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                    {tab === 'affiliation' ? (
                        <div id='affiliation'>
                            {affiliation ? (<ListView table="notification" data={affiliation} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                    {tab === 'exam' ? (
                        <div id='exam'>
                            {exam ? (<ListView table="notification" data={exam} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                    {tab === 'important' ? (
                        <div id='important'>
                            {important ? (<ListView table="notification" data={important} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                    {tab === 'news' ? (
                        <div id='news'>
                            {news ? (<ListView table="notification" data={news} columns={columns} title="" download="true" pageSize="20" />) : ("No data found")}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default OldAnnouncements;