
import SubHeader from "../components/SubHeader";

const HealthCenter = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Health Center</h2>
                <img className="my-2" src="/images/departments/healthcentre.jpg" alt="Vice-Chancellor" width="620rem" />

                <p className="-ta--j my-2">The University has a Health Centre with two Medical Officers, one Lady Medical Officer, one Nurse, two Technicians, one Pharmacist and other supporting staff. It has facilities of ECG, X-Ray scanners etc. Each student undergoes medical examination every year and is provided medical help as and when necessary. Each staff member is medically examined while joining the duties. Free medical service to the staff and students is provided. In case of serious ailments, the patients are referred to several leading designated hospitals throughout the country and admissible medical charges for advanced treatment and operations are reimbursed by the University. Many people from neighboring villages avail free medical facilities as a part of community service by the University Health Centre. NSS students organize special medical camps on health, AIDS/HIV awareness regularly as part of their community service in collaboration with the Health Centre.</p>
            </div>
        </div>
    );
}

export default HealthCenter;