import React, { useEffect, useState } from 'react';
import ListView from '../../components/ListView';
import SubBanner from "../../components/SubHeader";
import gs from '../../services/gs';

const NewsNotiLib = () => {
    const [adm, setAdm] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getListColumns('notification').then(res => {
            setColumns(res);
        });
        gs.getByType('notification', '52').then(res => {
            setAdm(res);
        });
    }, []);

    return (
        <>
            <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className="container my-4">
                <h3 className="heading">News & Notifications</h3>
                {adm ? (<ListView table="notification" data={adm} columns={columns} download="true" title="" pageSize="20" />) : ("No data found")}
            </div>
        </>
    )
}

export default NewsNotiLib;