import React, { useEffect, useState } from 'react';
import SubBanner from "../components/SubHeader";
import { useParams } from 'react-router-dom';
import { instAxios } from '../services/helper';

const Page = () => {
    const { pageId } = useParams();

    const [page, setPage] = useState();
    const [content, setContent] = useState();

    useEffect(() => {
        instAxios.get('/page/name?name=' + pageId).then(res => {
            setPage(res.data);
        });
    }, [pageId]);

    useEffect(() => {
        if (page !== undefined) {
            instAxios.get('/content/' + page.body).then(res => {
                setContent(res.data.content);
            });
        }
    }, [page]);

    return (
        <>
            {page && <SubBanner url={page.header} />}
            <div className='container my-3'>
                {page && <h3 className="heading">{page.label}</h3>}
                {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
            </div>
        </>
    );
}

export default Page;