import SubBanner from "../../components/SubHeader";
import ContactForm from "../../components/ContactForm";

const Suggestions = () => {
    return (
        <>
             <SubBanner url="others/1676021575238_lib_sub_banner2.jpg" />
            <div className='container my-3'>
                <h3 className="heading">Suggestions</h3>
                <ContactForm/>
            </div>
        </>
    )
}

export default Suggestions;