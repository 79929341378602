import { instAxios } from "./helper";
const user = JSON.parse(localStorage.getItem('user'));

const upload = (folder, file, onUploadProgress) => {

    let formData = new FormData();

    formData.append("file", file);
    return instAxios.post("/file/upload/" + folder, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + user.token 
        },
        onUploadProgress,
    });
};

const getFiles = () => {
    return instAxios.get("/file/list");
};

export default {
    upload,
    getFiles,
};