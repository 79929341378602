
import SubHeader from "../components/SubHeader";

const GuestHouse = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Guest House</h2>
                <img className="my-2" src="/images/departments/guesthouse.jpg" alt="Vice-Chancellor" width="620rem" />
                
                <p className="-ta--j my-2">The University has grown into one of the best learning centres and many scientific meetings, conferences, seminars, and workshops are organised regularly. In addition to scientific meetings, many faculty members/scientists from reputed national and international organizations visit the University on exchange programmes. The guest house has two independent buildings. In Kailasabhavan Guest House there are four suites, four double rooms and eight single rooms and the other Silver Jublee Guest House, has fourty eight single rooms, all with attached facilities. The guest house also has catering facilities with kitchen and well furnished dining hall attached.</p>
            </div>
        </div>
    );
}

export default GuestHouse;