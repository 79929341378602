import React, { useEffect, useState } from 'react';
import ListView from "../components/ListView";
import SubHeader from "../components/SubHeader";
import gs from '../services/gs';

const Syndicate = () => {
    const [editorState, setEditorState] = useState();
    const [posts, setPosts] = useState();
    const [columns, setColumns] = useState();

    useEffect(() => {
        gs.getContent('26').then(res => {
            setEditorState(res.content);
        });
        gs.getListColumns('syndicate').then(res => {
            setColumns(res);
        });
        gs.getAll('syndicate').then(res => {
            setPosts(res);
        });
    }, []);

    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h3 className="heading">The Syndicate</h3>
                <div dangerouslySetInnerHTML={{ __html: editorState }} />
                <ListView table="syndicate" data={posts} columns={columns} pageSize="30" title="Syndicate Members" />
            </div>
        </div>
    );
}

export default Syndicate;