import React from "react";

const colorObject = {
    'black': '#242526',
    'navy': '#083176',
    'orange': '#e77528',
    'primary': '#0262b9',
    'dark': '#3f4145',
    'grey': '#65686c',
    'secondary': '#38c6f4',
    'light': '#fff',
    'success': '#009054',
    'info': ' #0080dc',
    'warning': '#b96b00',
    'danger': '#ee3026',
    'muted': '#65686c',
    'atom': '#242526',
}

const Icon = ({ 'extra-class': extraClass, size, color, icon }) => {
    return (
        <div className={`icon ${extraClass && extraClass}`}>
            <svg
                className={`icon-svg ${size}`}
                stroke={colorObject[color]}
                fill={colorObject[color]}
                xmlns='http://www.w3.org/2000/svg'>
                <use xlinkHref={"/sprite.svg#" + icon} />
            </svg>
        </div>
    )
};

Icon.defaultProps = {
    size: "sm-2",
    color: "black"
}

export default Icon;