import React, { useEffect, useState } from 'react';
import gs from '../services/gs';
import SubHeader from "../components/SubHeader";
import { useTranslation } from "react-i18next";

const Deans = () => {
    const { t } = useTranslation();
    const [data, setData] = useState();

    useEffect(() => {
        gs.getByType('faculty', 61).then(res => {
            setData(res);
        });
    }, []);

    return (
        <>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">{t('deans')}</h2>
                <div className="row">
                    <div className="col-12">
                        <div className='row py-1 bg-light border-top border-bottom'>
                            <div className='col-1 fw-bold'>
                                #
                            </div>
                            <div className='col-5 fw-bold'>
                                {t('dean')}
                            </div>
                            <div className='col-5 fw-bold'>
                                {t('faculty')}
                            </div>
                        </div>
                        {
                            data && data.map((eachRow, index) => {
                                return (
                                    <div key={index} className='row border-bottom py-2'>
                                        <div className='col-1'>
                                            {index + 1}
                                        </div>
                                        <div className='col-5'>
                                            {eachRow.employee.label}
                                        </div>
                                        <div className='col-5'>
                                            {t(eachRow.name)}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Deans;