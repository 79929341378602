import React, { useEffect, useState } from 'react';

import Announcements from "../components/Announcements";
import Carousel from "../components/Carousel";
import Highlights from "../components/Highlights";
import NewsSection from "../components/NewsSection";
import AboutSectionUni from "../components/AboutSectionUni";
import AboutSectionVC from "../components/AboutSectionVC";
import GallerySection from "../components/GallerySection";
import gs from '../services/gs';

const Home = () => {

    const [cdata, setCdata] = useState();

    useEffect(() => {
        gs.getByType('carousel', '46').then(res => {
            setCdata(res);
        });
    }, []);

    const captionStyle = {
        fontSize: "2em",
        fontWeight: "bold",
    };
    const slideNumberStyle = {
        fontSize: "20px",
        fontWeight: "bold",
    };

    return (
        <div>
            <Carousel
                data={cdata}
                time={3000}
                width="100%"
                height="500px"
                captionStyle={captionStyle}
                radius="1px"
                slideNumber={false}
                slideNumberStyle={slideNumberStyle}
                captionPosition="bottom"
                automatic={true}
                dots={true}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                thumbnails={false}
                thumbnailWidth="100px"
                showNavBtn={true}
                style={{
                    textAlign: "center"
                }}
            />
            <Announcements />
            <div className="pt-5">
                <Highlights />
            </div>

            <div className="container-fluid py-5">
                <AboutSectionUni />
            </div>

            <div className="container-fluid section">
                <AboutSectionVC />
            </div>

            <div className="container-fluid my-5">
                <NewsSection />
            </div>

            <div className="container-fluid section">
                <GallerySection />
            </div>
        </div>
    )
};

export default Home;