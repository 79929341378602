import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BUCKET_URL, instAxios } from '../services/helper';

const NewsSection = () => {

    const [news, setNews] = useState();
    const [implink, setImplink] = useState();

    useEffect(() => {
        instAxios.get('notification/firstrows/6').then(res => {
            setNews(res.data);
        });
        instAxios.get('implink/firstrows/10').then(res => {
            setImplink(res.data);
        });
    }, []);

    const getUrl = (url) => {
        if (url.startsWith("http")) {
            return url;
        } else {
            return BUCKET_URL + url;
        }
    }

    return (
        <div className="container flex-container news-section">
            <div className="flex-child-container news" data-aos="fade-right">
                <h3 className="heading">News & Notifications</h3>
                <ul className='news-list'>
                    {news && news.map((eachRow) => {
                        return (
                            <li key={eachRow.id} className="list-item">
                                <div className="firstpart">
                                    <h6>{eachRow.udate.substring(0, 2)}</h6>
                                    <div>{eachRow.udate.substring(3, 5)}-{eachRow.udate.substring(6, 10)}</div>
                                </div>
                                <div className='secondpart'>
                                    <a href={getUrl(eachRow.url)} target='_blank'>{eachRow.title}</a>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <div className='d-flex justify-content-end'>
                    <Link className="read-more-btn" data-aos="fade-right" to="/notifications">
                        Read More
                    </Link>
                </div>
            </div>

            <div className="flex-child-container news" data-aos="fade-left">
                <h3 className="heading">Useful Links</h3>
                <ul className='news-list ms-2'>
                    {implink && implink.map((eachRow) => {
                        return (
                            <li className='list-item' key={eachRow.id}>
                                <a href={eachRow.url} target="_blank" rel="noreferrer" >{eachRow.title}</a>
                            </li>)
                    })}
                </ul>
                <div className='d-flex justify-content-end'>
                    <Link className="read-more-btn" data-aos="fade-right" to="/implink">
                        More Links
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default NewsSection;