import axios from "axios";

//export const BASE_URL = "http://gugapi-env.eba-vmyqkyjs.ap-south-1.elasticbeanstalk.com/";
//export const BASE_URL = "http://localhost:5000/api/";
export const BASE_URL = "https://api.gug.ac.in/api/";

//"proxy":"http://localhost:5000/"

export const BUCKET_URL = "https://gugapiimages.s3.ap-south-1.amazonaws.com/";

export const instAxios = axios.create({
  baseURL: BASE_URL
});

export const getMenuObject = (data) => {
  var tmenu = [];
  data.forEach(element => {
    if (element.parent === 0) {
      tmenu.push(element);
    } else {
      const index = tmenu.findIndex(el => el.id === element.parent); // should be in submenu
      if (index > -1) {
        if (tmenu[index].submenu)
          tmenu[index].submenu.push(element);
        else
          tmenu[index].submenu = [element];
      } else {
        var mainId;
        var subindex;
        tmenu.forEach((each) => {
          if (each.submenu) {
            subindex = each.submenu.findIndex(el => el.id === element.parent);
            mainId = each.id;
            if (subindex > -1)
              return true;
          }
        });
        const mainindex = tmenu.findIndex(el => el.id === mainId);
        if (subindex > -1) {
          if (tmenu[mainindex].submenu[subindex].submenu)
            tmenu[mainindex].submenu[subindex].submenu.push(element);
          else
            tmenu[mainindex].submenu[subindex].submenu = [element];
        }
      }
    }
  });
  return tmenu;
}