import SubHeader from "../components/SubHeader";

const ContactUs = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Contact Us</h2>
                <div className="row">
                    <div className="col-4">
                        <br />
                        <div className="default-heading">GULBARGA UNIVERSITY</div>
                        Jnana Ganga,<br />
                        Kalaburagi - 585 106,<br />
                        Karnataka State, India<br />
                        Tel No:+91 8472 263202
                        <br />
                        <br />
                        <br />
                        <div className="default-heading">Telephone Numbers</div>
                        <table className="light-table">
                            <tbody>
                                <tr>
                                    <td >Registrar(Adm)</td>
                                    <td >+91 8472 263202</td>
                                </tr>
                                <tr>
                                    <td >Registrar(Eval)</td>
                                    <td >+91 8472 263203</td>
                                </tr>
                                <tr>
                                    <td >Finance Officer</td>
                                    <td >+91 8472 263204</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-8">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3809.1074404914684!2d76.87080041536997!3d17.310364509576978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc8c1270787aaef%3A0x246a38c8d3f4a1ab!2sGulbarga%20University!5e0!3m2!1sen!2sin!4v1626435254794!5m2!1sen!2sin" height="100%" width="100%" allowfullscreen="" title="Location" loading="lazy"></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;