import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { instAxios } from "./helper";
// https://stackoverflow.com/questions/56748722/how-can-we-load-translations-using-api-calls-instead-of-having-them-defined-in-s

const loadResources = async (locale) => {
    return await instAxios.get('/translation/lang', { params: { lang: locale } }).then(res => {
        return Object.fromEntries(res.data.map((element) => [element.tkey, element.label]));
    });
}

const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: (options, url, payload, callback) => {
        try {
            const [lng] = url.split('|');
            loadResources(lng).then((response) => {
                callback(null, {
                    data: response,
                    status: 200,
                });
            });
        } catch (e) {
            console.error(e);
            callback(null, {
                status: 500,
            });
        }
    },
};

i18next
    .use(initReactI18next)
    .use(HttpApi) // Registering the back-end plugin
    .use(LanguageDetector) // Registering the detection plugin
    .init({
        backend: backendOptions,
        fallbackLng: "en",
        debug: false,
        load: "languageOnly",
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            useSuspense: false
        }
    });

export default i18next;