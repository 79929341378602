import SubHeader from "../components/SubHeader";

const Hostels = () => {
    return (
        <div>
            <SubHeader />
            <div className="container my-4">
                <h2 className="heading">Hostels</h2>
                <img className="my-2" src="/images/departments/hostels.jpg" alt="Hostels" width="620rem" />
                <p className="-ta--j my-2">Over 1,100 students stay in five gents hostels and three ladies hostels on the main campus. The lady research scholars are also provided with accommodation in the Women Hostel. The University has established Working Women Hostels in the Postgraduate Centres at Raichur, Bellary and Sandur to facilitate accommodation for all the lady students in the said PG Centers. These five spacious and well planned Working Women Hostels have been constructed with assistance from the Ministry of Human Resources Development (MHRD). The University plans to construct additional ladies and gents hostels to meet the ever-increasing demand in view of the new programs the University intends to introduce. The University hostels provide facilities like boarding, lodging, indoor games, newspaper/Magazines, reading rooms, visitors room, common prayer/recreation halls, television, telephones, separate kitchen, and dining hall etc. to ensure comfortable living for all students in all the hostels. In case of emergencies, doctors, medical facilities, and transportation are also provided.</p>
            </div>
        </div>
    );
}

export default Hostels;